<template>
  <v-app-bar class="main-header" height="40" fixed color="primary" dark>
    <!-- @click.stop="store.drawer = !store.drawer" -->
    <v-btn
      small
      icon
      class="mx-1 menu-btn"
      @click.stop="store.drawer = !store.drawer"
    >
      <template v-if="store.drawer">
        <v-icon style="font-size: 18px">mdi-arrow-left</v-icon>
      </template>
      <template v-else>
        <v-icon style="font-size: 18px">mdi-menu</v-icon>
      </template>
    </v-btn>
    <v-toolbar-title>HulkHire > Jobs</v-toolbar-title>
    <v-spacer></v-spacer>
    <sign-in v-if="!store.profile" ></sign-in>
    <!-- <v-btn @click.stop="onsignIn" color="#0077B5"
    dark
    elevation="2"
    rounded
    class="linkedin-signin" :width="$vuetify.breakpoint.mdAndUp? '250px':'130px'">
    <v-icon class="linkedin-icon">mdi-linkedin</v-icon>
    <span>{{$vuetify.breakpoint.mdAndUp ? "Sign In with LinkedIn" :"Sign In"}}</span>
    </v-btn> -->
    
    
    <!-- <v-btn
      v-if="!store.profile"
      style="background: #fff"
      small
      outlined
      color="primary"
      class="text-capitalize"
      @click="login"
      >Login
    </v-btn> -->
    <!-- <button style="color: #000; background: #fff;padding: 5px 10px;border-radius:3px;"> Login</button> -->
    <v-menu
      min-width="180"
      offset-y
      bottom
      left
      nudge-bottom="10"
      v-if="store.profile"
      style="z-index: 100000"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mr-0" icon v-bind="attrs" v-on="on" >
          <v-avatar size="40" color="blue">
    <v-img :src="store.profile.profile_url" alt="Profile Picture"></v-img>
  </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <div
          class="text-h5 grey--text text--darken-3 px-4 pt-4"
          style="text-transform: capitalize"
        >
          {{ store.profile.firstName }} {{ store.profile.lastName }}
        </div>
        <div
          class="subtitle-2 primary--text font-weight-regular px-4"
        >
          {{ store.profile.emailAddress }}
      </div>
        <div class="d-flex justify-center my-3">
          <v-btn
            width="80%"
            large
            outlined
            color="primary"
            class="text-capitalize"
            @click="logOut"
            >Sign Out
          </v-btn>
        </div>
      </v-list>
    </v-menu>
    
  </v-app-bar>
</template>
<script>
import { store } from "../../store/index";
import SignIn  from "../../components/SignIn/SignIn.vue";
import { getUserDetails, sendLogs } from "../../server/api";
import config from "../../config";


export default {
  name: "Header",
  components: {
    SignIn
  },
  data: () => ({
    config,
    searchCollapse: true,
    account: [],
    notificationsBadge: true,
    messageBadge: true,
    store,
    orderid:null,
    showsuccessdialog:false,
    showfailuredialog:false,
    paymenterror:"",
    count:0
  }),
  computed: {},
  methods: {
    logOut: function () {
      sendLogs({type:'LOGOUT',log:'' }).then(()=>{
        document.cookie =
        "jobs_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=.hulkhire.com;";
        window.location.href = "/";
      });
      
      
    },
    onsignIn: function(){
    sendLogs({type:'SIGN_IN',log:'' }).then(()=>{
      location.href='https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86ej6acokucwcw&redirect_uri=https%3A%2F%2Fapi.hulkhire.com%2Fauthcode&state=foobar&scope=r_liteprofile%20r_emailaddress';
    });
    
  },
  },
  
  mounted() {
    if (!store.profile) {
      getUserDetails().then((res) => {
        sendLogs({type:'USERDETAILS_LOADED',log:'' });
        store.profile=res;
      });
    } 
  },
};
</script>

<style src="./Header.scss" lang="scss"></style>
