export default {
    "Recruited": "Shortlisted",
    "Approved": "Shortlisted",
    "Invitation Sent": "Interview Scheduled",
    "Rejected": "Not Selected",
    "Waiting for evaluation": "Under Evaluation",
    "Moved to training": "Shortlisted",
    "On hold": "Under Evaluation",
    "No Show": "No Show",
    "BM Rejected": "Not Selected",
    "Slot Mail Sent": "Invited",
    "BM Verified": "Under Evaluation",
    "BM Under Verification": "Under Evaluation",
    "BM_Rejected": "Not Selected",
    "Ready to Hire": "Shortlisted",
    "Pending Invitation": "Interview Scheduled",
    "Moved to next Round": "Shortlisted",
    "Pending Slot Invitation": "Invited",
    "Cancelled": "Cancelled"
}
