<template>
  <v-app>
    <v-container class="pa-0 pt-10" fluid>
      <v-card class="schedule-card" style="
          max-width: 900px;
          margin-top: 100px;
          margin-left: auto;
          margin-right: auto;
        " v-if="!completed">
        <v-row class="scheduler-container ma-0">
          <v-col md="4" cols="12" class="profile-description">
            <div class="profile-logo">
              {{ $route.params.profile.toUpperCase() }}
            </div>
            <v-row class="mt-4 pl-2">
              <div class="green-circle-small"></div>
              <span class="">{{
                $route.params.testname.split("-").join(" ").toUpperCase()
              }}</span>
            </v-row>

            <div class="mt-10">
              <h6 class="font-weight-bold" style="font-size: 15px">
                Rounds :
                <span class="font-weight-regular" style="text-transform: capitalize">{{ skills }}</span>
              </h6>
            </div>
            <div class="mt-1" v-if="optionals">
              <h6 class="font-weight-bold" style="font-size: 15px">
                Good to have :
                <span class="font-weight-regular" style="text-transform: capitalize">{{ optionals }}</span>
              </h6>
            </div>
            <v-row class="ma-0">
              <i style="color:#008036" class="fa-solid fa-video mt-2 mr-2"></i>
              <span style="width: 80%">Interview link will be shared with you after confirmation</span>
            </v-row>
            <p class="mt-4 only-desktop">This is great!!!</p>
            <p class="only-desktop">
              Congratulations on taking big step towards your career.
              <span v-if="$vuetify.breakpoint.mdAndUp">
                Without wasting time, Book your interview slot and land your dream job.</span>
            </p>
          </v-col>
          <v-col md="8" cols="12" v-if="!schedule" class="p-2" style="
              align-items: center;
              display: flex;
              flex-direction: column;
              justify-content: center;
            ">
            <span :class="$vuetify.breakpoint.mdAndDown ? 'ma-0 ' : 'mb-10 ml-10'"><b>Note:</b> Interview can be given
              only in Laptop/Desktop with
              working camera and microphone. Schedule it for later, If you don't
              have it at the moment.</span>
            <div class=" d-flex mt-2 align-center">
              <v-col cols="1" class="mr-4">
                <label style="margin-top:-10px; zoom:0.8; width:48px;border-radius: 4px; background-color: #3FC351;"
                  class="d-flex pr-0">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"
                    width="48px" height="48px">
                    <path style="fill:#FFFFFF;"
                      d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z" />
                    <g>
                      <path style="fill:#FFFFFF;"
                        d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z" />
                      <path style="fill:#CFD8DC;"
                        d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z" />
                    </g>
                    <path style="fill:#40C351;"
                      d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z" />
                    <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;"
                      d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z" />
                  </svg>
                </label>
              </v-col>
              <v-col cols="3" class="pr-0">
                <v-select :items="countries" item-text="text" item-value="phonecode" v-model="selectedCountry"
                  style="margin-top:4px;">
                  <template v-slot:selection="data">
                    <!-- <span > -->
                    {{ data.item.flag }} {{ data.item.phonecode }}
                    <!-- </span> -->
                  </template>
                </v-select>
              </v-col>
              <v-col cols="7" class="pl-0">
                <v-text-field v-model="phoneNumber" placeholder="Whatsapp Contact number"
                  :rules="[rules.required, rules.phoneNumber]"></v-text-field>
              </v-col>
            </div>
            <v-btn class="primary mt-2" v-if="skills && skills.toUpperCase().indexOf('IN-PERSON')==-1" elevation="3" :disabled="!phoneNumber || !pattern.test(phoneNumber)" x-large
              @click="setschedule('now')">Give Interview Now</v-btn>
            <br />
            <v-btn style="border-width: 2px; color: #008036" class="mt-4" outlined
              :disabled="!phoneNumber || !pattern.test(phoneNumber)" elevation="3" x-large
              @click="setschedule('later')">Schedule Interview For Later</v-btn>
          </v-col>
          <v-col md="4" cols="12" v-if="schedule && !timepicker" class="p-2">
            <h5 class="font-weight-bold">Select a Date & Time</h5>
            <v-date-picker v-bind:class="{ 'has-value': !!datepicker }" no-title @input="selectedtime = null"
              v-model="datepicker" :min="getMinDate()" :max="getMaxDate()"></v-date-picker>
            <v-select :items="timezoneList" item-text="txt" item.value="value" v-model="timezone" style="margin-top:2px;">
            </v-select>
          </v-col>
          <v-col md="4" sm="12" xs="12" v-if="schedule && datepicker && !timepicker">
            <span class="only-mobile" style="align-items: center"><i @click="
              datepicker = null;
            selectedtime = null;
            " style="margin-right: 10px; color: green" class="fa fa-arrow-left" aria-hidden="true"></i>
              Selected Date - {{ datepicker }}
            </span>
            <div class="v-schedule-time-list mt-12">
              <div v-for="time in times" v-bind:key="time">
                <v-btn v-if="selectedtime != time" style="width: 100%" @click="selectedtime = time" outlined
                  color="primary" :disabled="Date.parse(window.SERVER_TIME) >
                    Date.parse(new Date(datepicker + ', ' + time + ' ' + timezone.split('$$$')[1]))
                    ">{{ time }}</v-btn>
                <div v-if="selectedtime == time">
                  <v-row style="margin: 0; justify-content: space-between">
                    <v-btn dark color="gray" class="selectedtime">{{
                      time
                    }}</v-btn>
                    <v-btn
                      @click="timepicker = selectedtime; sendLogs({ type: 'SCHEDULE_INTERVIEW_TIME_SELECTED', log: selectedtime }); verified && scheduleInterview()"
                      dark class="confirm">Confirm</v-btn>
                  </v-row>
                </div>
              </div>
            </div>
          </v-col>
          <v-col md="8" class="d-flex flex-column justify-center align-center"
            v-if="schedule && datepicker && timepicker && verified == null">
            <v-divider width="100%" class="mt-10 mb-10" v-if="$vuetify.breakpoint.smAndDown"></v-divider>
            <h5 v-if="datepicker && timepicker"><i class="fa-solid fa-calendar-check"></i> {{ (new
              Date(datepicker)).toLocaleDateString("en-GB").split("/").join("-") }}, &nbsp;{{ timepicker }} </h5>
            <h4>Verify your identity to confirm slot</h4>

            <v-btn @click.stop="onsignIn" color="#0077B5" dark elevation="2" rounded
              class="linkedin-signin align-self-center" width="250px" :disabled="linkedinDisabled">
              <v-icon class="linkedin-icon">mdi-linkedin</v-icon>
              <span>Verify with LinkedIn</span>
            </v-btn>
            <div class="loader" v-if="linkedinDisabled"></div>
            <v-divider width="100%" class="mt-10 mb-10" v-if="$vuetify.breakpoint.smAndDown"></v-divider>
          </v-col>
          <v-col md="8" class="d-flex flex-column justify-center align-center" v-if="verified == false">
            <h4>Verification failed</h4>
          </v-col>
          <v-col md="8" class="verified-candidate d-flex flex-column justify-center align-center"
            v-if="verified && !completed && schedule && datepicker && timepicker">
            <h5>Verification successful
              <v-icon color="green" size="40">mdi-checkbox-marked-circle-outline</v-icon>

            </h5>

            <h5 v-if="schedule != 'now'">Scheduling Interview Now...</h5>
            <h5 v-if="(schedule == 'now')">Starting Interview Now...</h5>
            <div class="loader"></div>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-if="completed && error" class="confirmation-box">
        <h3>{{ error }}</h3>
      </v-card>
      <v-card class="confirmation-box" v-if="schedule != 'now' && completed && !error">
        <h3>Confirmed</h3>
        <p>
          Your interview is scheduled with
          {{ $route.params.profile.toUpperCase() }}
        </p>
        <hr />
        <v-row>
          <div class="green-circle-small"></div>
          {{ $route.params.testname.split("-").join(" ").toUpperCase() }}
        </v-row>
        <v-row v-if="interviewtime">
          <i class="fa-solid fa-calendar-check"></i>
          {{ new Date(interviewtime).toLocaleString("en-US", {
            timeZone: timezone.split("$$$")[0], weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric", hour: "numeric", minute: "numeric"
          }) }}
          <!-- {{ new Date(interviewtime).toDateString() }} , &nbsp;{{ timepicker }} -->
        </v-row>
        <v-row>
          <i class="fa-solid fa-earth-asia"></i>
          <span>{{
            timezone.split("$$$")[0]
          }}</span>
        </v-row>
        <v-row>
          <i class="fa-solid fa-video"></i>

          <span>Interview link is shared with you on your email - <a :href="'mailto:' + email">{{ email }}</a>. Please
            check your spam folder, if not received.

          </span>
          <br />
          Please be informed, Interview can be given only in Laptop/Desktop with
          working camera and microphone.
          <br />
        </v-row>

        <h5>A calendar invitation has been sent to your email address - <a :href="'mailto:' + email">{{ email }}</a>.
          Please check your spam folder, if not received.</h5>
        <hr style="margin-bottom: 50px" />
      </v-card>
      <v-card class="confirmation-box" v-if="schedule == 'now' && completed && !error">
        <h3>Get Ready for Interview</h3>
        <p>
          Your interview is started with
          {{ $route.params.profile.toUpperCase() }}
        </p>
        <hr />
        <v-row>
          <div class="green-circle-small"></div>
          {{ $route.params.testname.split("-").join(" ").toUpperCase() }}
        </v-row>
        <v-row>
          <strong>Please be informed, Interview can be given only in Laptop/Desktop
            with working camera and microphone.</strong>
          <br />
          <div v-if="$vuetify.breakpoint.mdAndUp" style="width:100%">
            You can start your interview by clicking on below button
            <v-row style="width: 100%" class="justify-center ma-6">
              <v-btn v-if="schedule == 'now'" target="_blank" color="primary" :href="interviewlink">
                <i class="fa-solid fa-video white--text"></i> Start my
                interview</v-btn>

            </v-row>
            Interview link is also shared on your email address -
            <a :href="'mailto:' + email">{{ email }}</a>. Please check your spam folder, if not received.
          </div>
          <div v-if="$vuetify.breakpoint.smAndDown">
            <v-row>
              Interview link is shared on your email address -
              <a :href="'mailto:' + email">{{ email }}</a>. Please check your spam folder, if not received. <br /> <span
                class="d-flex mt-4" v-if="schedule == 'now'">Open the interview link in Laptop/Desktop to start your
                interview immediately.</span>
            </v-row>
          </div>


          <br />
        </v-row>
        <hr style="margin-bottom: 50px" />
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import config from "../../config";
import helperUtils from "../../helper.js";
import { sendLogs } from "@/server/api";
let serverurl = config.serverurl;
export default {
  name: "SelfInvitation",
  components: {},
  data() {
    return {
      selectedCountry: null,
      phoneNumber: null,
      countries: [
        // { code: "+1", name: "United States" },
        // { code: "+44", name: "United Kingdom" },
        // { code: "+91", name: "India" },
        // // add more countries as needed
      ],
      rules: {
        required: (value) => !!value || "Required.",
        phoneNumber: (value) =>
          /^[1-9]\d{1,14}$/.test(value) ||
          "Please enter a valid phone number.",
      },
      pattern: /^[1-9]\d{1,14}$/,
      verified: null,
      window: window,
      completed: false,
      times: [],
      schedule: null,
      datepicker: null,
      timepicker: null,
      interviewtime: null,
      firstname: "",
      lastname: "",
      email: "",
      interviewlink: "",
      error: false,
      selectedtime: null,
      linkedinDisabled: false,
      skills: [],
      optionals: [],
      sourcing_category: null,
      timezone: null,
      timezoneList: []
    };
  },
  methods: {
    sendLogs: sendLogs,
    setschedule(type) {
      sendLogs({ type: "PHONE_NUMBER_SHARED", log: this.selectedCountry + this.phoneNumber })
      sendLogs({ type: "SCHEDULE_INTERVIEW_CLICKED", log: type })
      this.schedule = type;
      if (type == "now") {
        this.datepicker = new Date()
          .toLocaleDateString("en-GB")
          .split("/")
          .reverse()
          .join("-");
        this.timepicker = helperUtils.formatTimeAMPM(new Date());
        if (this.verified) {
          this.scheduleInterview()
        }
      }
    },
    scheduleInterview(data) {
      if (!data) {
        data = {
          interviewtime: this.schedule == "now" ? (new Date()) : new Date(
            this.datepicker.replace(/-/g, "/") + ", " + this.timepicker + " " + helperUtils.getTimezoneOffset(this.timezone.split("$$$")[0])
          ),
          firstname: this.firstname,
          lastname: "",
          email: this.email,
          whatsapp: this.selectedCountry + this.phoneNumber,
          timezone: this.timezone
        }
        this.interviewtime = data.interviewtime;
        this.timepicker = helperUtils.formatTimeAMPM(
          new Date(data.interviewtime)
        );
      }
      
      sendLogs({ type: "SCHEDULING_INTERVIEW", log: data.interviewtime })
      fetch(serverurl + "/sendInvitation", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          candidates: [
            {
              firstname: data.firstname,
              lastname: data.lastname,
              email: data.email.trim(),
              profile_url: data.profile_url,
              whatsapp: data.whatsapp,
              timezone: data.timezone
            },
          ],
          type: "SELF",
          interviewtime: data.interviewtime,
          sourcing_category: this.sourcing_category,
          testid: this.$route.params.testid,
          profile: this.$route.params.profile,
        }),
      })
        .then((s) => s.json())
        .then((s) => {
          this.completed = true;
          this.schedule = data.schedule;
          this.interviewlink = s.link;
          this.email = data.email;
          this.timezone = data.timezone.split("$$$")[0]
          if (s.results && s.results.length == 0)
            this.error =
              "You have already scheduled your interview. Please check your email for the interview link. If you have not received the email, please check spam/junk folder.";
          else
            sendLogs({ type: "INTERVIEW_SCHEDULED", log: this.schedule })
        });
    },
    getMaxDate() {
      let date = new Date();
      date.setDate(date.getDate() + 15);
      return date.toISOString().slice(0, 10);
    },
    getMinDate() {
      return new Date().toISOString().slice(0, 10);
    },
    onsignIn() {
      this.linkedinDisabled = true;
      sendLogs({ type: "SIGN_IN_INTERVIEW", log: "" }).then(() => {
        let data = {
          url: location.href,
          verified: false,
          interviewtime: this.schedule == "now" ? (new Date()) : new Date(
            this.datepicker.replace(/-/g, "/") + ", " + this.timepicker + " " + helperUtils.getTimezoneOffset(this.timezone.split("$$$")[0])
          ),
          schedule: this.schedule,
          timezone: this.timezone,
          whatsapp: this.selectedCountry + this.phoneNumber
        };

        location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86ej6acokucwcw&redirect_uri=https%3A%2F%2Fapi.hulkhire.com%2Fauthcode&state=${encodeURIComponent(
          JSON.stringify(data)
        )}&scope=r_liteprofile%20r_emailaddress`;
      });
    },
    parseJwt(token) {

      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      console.log(JSON.parse(jsonPayload));
      return JSON.parse(jsonPayload);
    }
  },


  created() {
    // let url = new URL(location.href);
    let profile = this.$route.params.profile;
    let urldata = this.$route.params.data;
    if (urldata) {
      sendLogs({ type: "LINKEDIN_VERIFIED", log: urldata })
      this.$router.replace({
        name: "Book your slot",
        params: {
          profile: this.$route.params.profile,
          testname: this.$route.params.testname,
          testid: this.$route.params.testid,
          linkid: this.$route.params.linkid,
        },
      });
      let data = JSON.parse(decodeURIComponent(urldata));
      this.interviewtime = data.interviewtime;
      this.schedule = data.schedule;
      this.timepicker = helperUtils.formatTimeAMPM(
        new Date(data.interviewtime)
      );
      this.verified = data.verified;
      if (data.verified) {
        setTimeout(() => this.scheduleInterview(data), 5000);
      }
    }
    else {
      sendLogs({ type: "SCHEDULE_PAGE_LOADED", log: "" })
      if (this.$route.query.token) {
        let data = this.parseJwt(this.$route.query.token)
        console.log(data);
        console.log(this.parseJwt(this.$route.query.token));
        if (data) {
          this.verified = true;
          this.firstname = data.firstname;
          this.email = data.email;
        }

      }

    }

    document.title = "Book your interview slot with " + profile;
    fetch(serverurl + "/getTestDetails?id=" + this.$route.params.testid)
      .then((res) => res.json())
      .then((res) => {
        if (res.length == 0) {
          sendLogs({ type: "SCHEDULE_INTERVIEW_INACTIVE", log: "" })
          this.completed = true;
          this.error =
            "Company is no longer accepting interview for this job profile. Good luck for your job search.";
          return;
        }
        if (!res[0].requiredskill) {
          sendLogs({ type: "SCHEDULE_INTERVIEW_SOURCING", log: res[0].sourcing_category })
          if (res[0].sourcing_category == "IT") {
            res[0].requiredskill = "Python / Java / React / Angular / NodeJS (Any 1)";
            this.sourcing_category = res[0].sourcing_category
          }

        }
        this.skills = res[0].requiredskill
          .split(",")
          .join(", ")
          .replace(/_/g, " ");
        this.optionals = res[0].optionalskill
          ? res[0].optionalskill.split(",").join(", ").replace(/_/g, " ")
          : "";
      });

  },
  async mounted() {
    let x = 30; //minutes interval
    let tt = 8 * 60; // start time
    let ap = ["AM", "PM"]; // AM-PM

    for (let i = 0; tt < 21 * 60; i++) {
      let hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      let mm = tt % 60; // getting minutes of the hour in 0-55 format
      this.times[i] =
        ("0" + (hh % 12)).slice(-2) +
        ":" +
        ("0" + mm).slice(-2) +
        " " +
        ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt = tt + x;
    }
    this.times[this.times.indexOf("00:00 PM")] = "12:00 PM";
    this.times[this.times.indexOf("00:30 PM")] = "12:30 PM";

    let results = await fetch(serverurl + "/countries");
    let countries = await results.json();
    countries.sort((a, b) => a.name > b.name).map(c => c.text = c.flag + " " + c.name + " (+" + c.phonecode.replace("+", "").split(" ")[0] + ")");
    this.countries = countries;
    let userlocation = await fetch(serverurl + "/getLocation");
    let location = await userlocation.json();
    this.selectedCountry = countries.find(c => c.isoCode == location.countryCode).phonecode;
    countries.map(c => c.timezones.map(s => this.timezoneList.push({ txt: s.zoneName + (' (' + s.gmtOffsetName + ')'), value: s.zoneName + "$$$" + s.gmtOffsetName, offset: s.gmtOffset })));
    this.timezoneList.sort((a, b) => a.offset - b.offset)
    this.timezone = this.timezoneList.find(c => c.txt.toLowerCase().indexOf(location.timezone.toLowerCase()) > -1).value;
  },
};
</script>

<style src="./SelfInvitation.scss" lang="scss"/>
