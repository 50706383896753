<template>
  <v-navigation-drawer
    app
    clipped
    v-model="store.drawer"
    :mini-variant="!store.drawer"
    :width="sidebarWidth"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="sidebarMinWidth"
    :class="{ 'drawer-mini': !store.drawer }"
  >
    
    <v-list>
      <template v-for="(item, i) in items" >
        
        <v-row v-if="item.heading" :key="item.heading" align="center">
          <v-col cols="6" class="py-5">
            <span
              style="padding-left: 32px"
              class="text-body-1 subheader"
              :class="item.heading && store.drawer ? 'show ' : 'hide'"
            >
              {{ item.heading }}
            </span>
          </v-col>
          <v-col cols="6" class="text-center"> </v-col>
        </v-row>
        <v-divider
          v-else-if="item.divider"
          :key="i"
          dark
          class="my-4"
        ></v-divider>
        <v-list-group
          color="primary"
          v-else-if="item.children && store.drawer"
          :key="item.title"
          v-model="item.model"
          append-icon=""
        >
          <template v-slot:prependIcon>
            <v-icon size="28">mdi-image-filter-none</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="grey--text">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            :to="child.link"
            link
          >
            <v-list-item-action v-if="child.icon">
              <v-icon size="">{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="grey--text">
                {{ child.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          color="primary"
          v-else
          :key="item.text"
          :href="item.href ? item.href : null"
          :to="item.link === '#' ? null : item.link"
          link
        >
          <v-list-item-action>
            <v-icon size="28" :color="item.color ? item.color : ''">{{
              item.icon
            }}</v-icon>
          </v-list-item-action>
          <v-list-item-content v-if="windowWidth < 900 || windowWidth > 1500">
            <v-list-item-title class="grey--text" link>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        

        
      </template>
      <div style="position:absolute; bottom:0; font-size: 10px;
    padding-left: 5px;">
        <v-col cols="6" class="pl-0 pr-0">
        <v-select
          v-model="country"
          :items="countries"
          menu-props="auto"
          label="Country"
          hide-details
          @change="CountryChange()"
          single-line
          item-text="text"
          item-value="isoCode"
          return-value
          solo
          style="width:120px"
          class="no-padding"
        >
        <template v-slot:selection="data">
             <span style="font-size:30px;">{{data.item.flag}}</span>
          </template>
        </v-select>
      </v-col>
          <b>Contact Us</b><br/>
          <a href="mailto:care@hulkhire.com">care@hulkhire.com</a><br/>
          <a href="tel:8886080289">+91 88860 80289</a>
          <!-- <p>402, Jugla Technologies,<br/> Begumpet, Hyderabad</p> -->
          <br/>
          <a style="color:gray" target="_blank" href="https://hulkhire.com/terms/">Terms & Conditions</a>
          <br/>
          <a style="color:gray" target="_blank" href="https://hulkhire.com/privacy/">Privacy Policy</a>
        </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { store } from "../../store/index";
import config from "../../config";
import  helper  from "../../helper";


let serverurl = config.serverurl;
// let window;
export default {
  props: {
    source: String,
  },
  data() {
    return {
      store,
      windowWidth: window.innerWidth,
      countries:[],
      country: document.cookie,
      items: [
        { title: "Jobs", icon: "mdi-briefcase", link: "/" },
        { title: "Interviews", icon: "mdi-video-account", link: "/interviews" },
        { title: "Profile", icon: "mdi-account-box", link: "/profile" },
        { title: "Tech talks", icon: "mdi-calendar", link: "/techtalks" }
        
      ],
      sidebarWidth:
        window.innerWidth < 900 || window.innerWidth > 1500 ? 240 : 96,
      sidebarMinWidth: 96,
    };
  },
  methods:{
    CountryChange:function(){
      document.cookie = "countrycode="+this.country+"; expires=Thu, 01 Jan 3025 00:00:00 UTC; domain=.hulkhire.com; Secure; SameSite=None;";
      location.reload()
    }
  },
  async mounted() {
    let results= await fetch(serverurl+"/countries");
    let countries = await results.json();
    countries.sort((a,b)=>a.name>b.name).map(c=>c.text= c.flag+" "+c.name);
    this.countries=countries;
    this.country=helper.getCookie("countrycode");
    if(!this.country){
      let results= await fetch(serverurl+"/getLocation");
      let location = await results.json();
      this.country= location.countryCode;
    }
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      this.sidebarWidth =
        window.innerWidth < 900 || window.innerWidth > 1500 ? 240 : 96;
    });
    
  },
};
</script>

<style src="./Sidebar.scss" lang="scss"/>
