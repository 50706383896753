import Vue from 'vue';
import Router from 'vue-router';

import Layout from '@/components/Layout/Layout';

import Error from "@/pages/Error/Error";
import Jobs from "@/pages/Jobs/Jobs";
import Profile from "@/pages/Profile/Profile";
import Interviews from "@/pages/Interviews/Interviews";
import TechTalks from "@/pages/TechTalks/TechTalks";
import FAQs from "@/pages/FAQs/faqs";

import SelfInvitation from "@/pages/SelfInvitation/SelfInvitation";
import Reschedule from "@/pages/Reschedule/Reschedule";

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    // {
    //   path: '/login',
    //   name: 'Login',
    //   component: Login
    // },
    // {
    //   path: '/signup',
    //   name: 'Sign up',
    //   component: Login
    // },
    {
      path: '/schedule/:profile/:testname/:testid/:linkid/:data(.*)?',
      name: 'Book your slot',
      component: SelfInvitation
    },
    {
      path: '/reschedule/:param1/:param2',
      name: 'Reschedule your interview',
      component: Reschedule
    },
    {
    path: '/',
    // redirect: 'login',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: 'jobs',
        name: 'Jobs',
        component: Jobs,
      },
      {
        path: '/',
        name: 'Jobs',
        component: Jobs,
      },
      {
        path: '/profile',
        name: 'Profile',
        component: Profile,
      },
      {
        path: '/faqs',
        name: 'FAQs',
        component: FAQs,
      },

      {
        path: '/interviews',
        name: 'Interviews',
        component: Interviews,
      },

      {
        path: '/techtalks',
        name: 'Tech Talks',
        component: TechTalks,
      }
    ],
  },
    {
      path: '*',
      name: 'Error',
      component: Error
    }
  ],
});
