<template>
  <div>
    <VueBotUI
      :messages="data"
      :options="botOptions"
      @init="onOpen"
      @msg-send="messageSendHandler"
      :bot-typing="botTyping"
      :input-disable="disableInput"
    />
  </div>
</template>
<script>
import { store } from "@/store/index";
import { getUserDetails, sendFeedback, sendLogs } from "@/server/api";
//<!-- :input-disable="disableInput" -->
//import {
//   Plugin,// eslint-disable-line
//   Block,// eslint-disable-line
//   BlockData,// eslint-disable-line
//   BlockMeta,// eslint-disable-line

//   createBot,
//   BOTUI_BLOCK_TYPES // eslint-disable-line
// } from 'botui' // eslint-disable-line
import chats from "./data";
import { VueBotUI } from "vue-bot-ui";
export default {
  name: "bot-app",
  components: { VueBotUI },
  data() {
    return {
      context: "",
      data: [], // See Data example below
      botOptions: {
        botTitle: "Growth",
        colorScheme: "#008036",
        bubbleBtnSize: "40",
        botAvatarImg:
          "https://cdn2.hubspot.net/hub/23753783/hubfs/1_lyyXmbeoK5JiIBNCnzzjjg.png?width=108&height=108",
        // See the list of options below
      },
      query: "",
      feedback: "",
      technicalAssistance: "",
      email: "",
      botTyping: false,
      disableInput: true,
    };
  },
  methods: {
    sendData(content, type) {
      sendLogs({type:(type+"_SHARED"), email:this.email, log: content})
      sendFeedback({
        usertype:this.context,
        email: this.email,
        msgtype:type,
        content
      })
      
      if(this.context=="CANDIDATE")
        this.data.push(chats["THANK_DETAILS"]);
      else if(this.context=="RECRUITER")
        this.data.push(chats["THANK_DETAILS_RECRUITER"]);
      this.disableInput = true;
    },
    async messageSendHandler(val) {
      sendLogs({type:"CHAT_MSG", email:this.email, log: val.value|| val.text})
      this.botTyping = true;
      await this.timeout(500);
      if (val.value == "CANDIDATE" || val.value == "RECRUITER")
        this.context = val.value;

      this.data.push({
        agent: "user",
        type: "text", // always
        text: val.text,
      });
      let lastdata = this.data[this.data.length - 2];
      if (
        lastdata.msgtype == "FEEDBACK" ||
        lastdata.msgtype == "QUERY" ||
        lastdata.msgtype == "TECHNICAL_ASSISTANCE" ||
        lastdata.msgtype == "NO_JOB"
      ) {
        if (!this.email) {
          this.data.push({
            agent: "bot",
            type: "text",
            text: "Please share your email",
            disableInput: false,
            msgtype: "EMAIL",
          });
        } else {
          this.sendData(
            this.data[this.data.length - 1].text,
            this.data[this.data.length - 2].msgtype
          );
        }
      } else if (lastdata.msgtype == "EMAIL") {
        this.email = val.text;
        this.sendData(
          this.data[this.data.length - 3].text,
          this.data[this.data.length - 4].msgtype
        );
      } else {
        if (
          val.value == "FEEDBACK" ||
          val.value == "QUERY" ||
          val.value == "TECHNICAL_ASSISTANCE" ||
          val.value == "NO_JOB" ||
          val.value == "EMAIL"
        )
          this.disableInput = false;
        else {
          this.disableInput = true;
        }
        this.data.push(chats[val.value]);
      }

      this.botTyping = false;

      setTimeout(
        () =>
          document
            .querySelectorAll(".qkb-msg-bubble-component__text")
            .forEach((s) => (s.innerHTML = s.innerText)),
        10
      );
    },
    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async onOpen() {
      sendLogs({type:"CHATBOX_OPENED", email:this.email, log:".."})
      

      if (this.data.length == 0) {
        this.botTyping = true;
        await this.timeout(500);
        this.data.push({
          agent: "bot",
          type: "text",
          text: "Hello. I am Growth...",
          disableInput: true,
        });
        await this.timeout(500);

        this.data.push({
          agent: "bot",
          type: "button",
          text: "How are you?",
          disableInput: true,
          options: [
            {
              text: "I'm Awesome!",
              value: "AWESOME",
              action: "postback",
            },
            {
              text: "I'm Good",
              value: "GOOD",
              action: "postback",
            },
            {
              text: "Bad!",
              value: "BAD",
              action: "postback",
            },
          ],
        });
        this.botTyping = false;
      }
      setTimeout(
        () =>{
          document
            .querySelectorAll(".qkb-msg-bubble-component__text")
            .forEach((s) => (s.innerHTML = s.innerText))
            let chatcontent= document.querySelector(".qkb-board-content")
      chatcontent.scrollTo(0, chatcontent.scrollHeight);
        }
          ,
        10
      );
    },
  },
  async mounted() {
    if (!store.profile) {
      getUserDetails().then((res) => {
        res? (this.email = res.email) : null;
      });
    } else this.email = store.profile.email;
  },
};
</script>
<style>
.qkb-mb-button-options__btn {
  color: #008036 !important;
  border-color: #008036 !important;
}
.qkb-mb-button-options__btn:hover {
  color: #fff !important;
  background-color: #008036 !important;
}
.qkb-msg-bubble--user .qkb-msg-bubble-component__text{
  color: #fff;
  background-color: #008036 !important;
}
.qkb-msg-bubble-component__options-wrapper {
  display: none !important;
}
.qkb-msg-bubble.qkb-msg-bubble--bot:last-child
  .qkb-msg-bubble-component__options-wrapper:last-child {
  display: flex !important;
}

@media screen and (max-width: 600px) {
  .qkb-board{
    position: fixed !important;
    top: 0 !important;
    height: calc(100vh - 1.5rem - 32px) !important;
    width: 100vw !important;
}  
.qkb-bot-bubble{
  z-index: 9999 !important;
  bottom: 77px !important;
    position: fixed !important;
}
}

</style>