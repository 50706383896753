let recruiterOptions=[
  {
    text: "How to do Sourcing with HulkHire?",
    value: 'SOURCING_WORKS',
    action: 'postback'
  },
  {
    text: "How to do Screening with HulkHire?",
    value: 'SCREENING_WORKS',
    action: 'postback'
  },
  {
    text: "Tell me about Pricing",
    value: 'RECRUITER_PRICING',
    action: 'postback'
  },
  {
    text: 'I want to give feedback',
    value: 'FEEDBACK',
    action: 'postback'
  },
  {
    text: 'I have a query',
    value: 'QUERY',
    action: 'postback'
  },
  {
    text: 'I am facing a technical issue',
    value: 'TECHNICAL_ASSISTANCE',
    action: 'postback'
  },
  {
    text: 'How to contact customer support?',
    value: 'CUSTOMER_SUPPORT',
    action: 'postback'
  }
];
export default {
  "AWESOME": {
    agent: 'bot', 
    type: 'button',
    text: `Loved your energy <span style="color:red; font-size:20px;">&hearts;</span> <br/>       Please tell me how can HulkHire help you?`, 
    disableInput: true, 
    options: [
      {
        text: "I am Candidate",
        value: 'CANDIDATE',
        action: 'postback'
      },
      {
        text: "I am Recruiter",
        value: 'RECRUITER',
        action: 'postback'
      }
    ],
  },
  "GOOD": {
    agent: 'bot', 
    type: 'button',
    text: 'Good to hear! <br/>Please tell me how can HulkHire help you?', 
    disableInput: true, 
    options: [
      {
        text: "I am Candidate",
        value: 'CANDIDATE',
        action: 'postback'
      },
      {
        text: "I am Recruiter",
        value: 'RECRUITER',
        action: 'postback'
      }
    ],

  },
  "BAD": {
    agent: 'bot', 
    type: 'button',
    text: 'Oh! Hopefully things will get better soon!<br/> Please tell me how can HulkHire help you?', 
    disableInput: true, 
    options: [
      {
        text: "I am Candidate",
        value: 'CANDIDATE',
        action: 'postback'
      },
      {
        text: "I am Recruiter",
        value: 'RECRUITER',
        action: 'postback'
      }
    ],
  },
  "CANDIDATE":{
    agent: 'bot', 
    type: 'button',
    text: '<b>Awesome!</b><br/> HulkHire allows you to connect with HR professionals directly and discuss your qualifications and interests with them. <br/> Get your interview arranged in no time.<br/>', 
    disableInput: true, 
    options: [
      {
        text: "How it works?",
        value: 'CANDIDATE_WORKS',
        action: 'postback'
      },
      {
        text: "I want to know about pricing",
        value: 'CANDIDATE_PRICING',
        action: 'postback'
      },
      {
        text: 'I want to share Feedback',
        value: 'FEEDBACK',
        action: 'postback'
      },
      {
        text: 'I have a Query',
        value: 'QUERY',
        action: 'postback'
      },
      {
        text: 'I am facing technical issue',
        value: 'TECHNICAL_ASSISTANCE',
        action: 'postback'
      },
      {
        text: 'Not able to find relevant jobs',
        value: 'NO_JOB',
        action: 'postback'
      }
    ],
  },
  "RECRUITER":{
    agent: 'bot', 
    type: 'button',
    text: '<b>Awesome!</b> <br/><br/>You are at right place. <br/><br/>HulkHire is great for finding top talent with minimal efforts.<br/> We already have ready to hire candidates for you. <br/>  Just signup on this link  and start hiring -<br/> <a target="_blank" href="https://talent.hulkhire.com/#/signup">Talent Portal<a><br/>', 
    disableInput: true, 
    options: recruiterOptions
  },
  "RECRUITER_MORE_QUESTIONS":{
    agent: 'bot',
    type: 'button',
    text: 'Please tell us what you would like to discuss',
    disableInput: true,
    options: recruiterOptions
  },
  "SOURCING_WORKS":{
    agent: 'bot',
    type: 'button',
    text: 'Here is simple process to <b>hire top talent with HulkHire</b><br/><br/>HulkHire offers comprehensive pool of skilled candidates\' already assessed on technical, communication, and coding skills.<br/> We also collect all necessary candidate information during the interview process.<br/><br/> Recruiters can review top-scoring candidates\' interview videos and details on our portal. <br/><br/>If interested, recruiters can purchase candidates\' contact details at a low price and hire them with just a few clicks. <br/><br/> Our streamlined process saves time, money, and ensures high-quality candidate selection.',
    disableInput: true,
    options: [
      {
        text: "I have more questions!",
        value: 'RECRUITER_MORE_QUESTIONS',
        action: 'postback'
      },
      {
        text: 'Thank you',
        value: 'THANK_YOU',
        action: 'postback'
      }],
  },
  "SCREENING_WORKS":{
    agent: 'bot',
    type: 'button',
    text: 'Here is simple process to <b>filter top 1% talent from hundreds with HulkHire</b><br/><br/>Our screening service provides recruiters with a unique link that can be shared with candidates to schedule or complete an automated video interview.<br/><br/> The interview process is 10 minutes and AI-based, where questions are asked on the candidate\'s screen, and their video is recorded and evaluated by our system baseed on their technical, communication and coding skills. <br/><br/>HulkHire provides a score to every candidate based on technical, communication, and coding skills. To ensure a higher conversion rate, we send regular reminders to candidates via email and WhatsApp. <br/><br/> Recruiters can then filter the top candidates based on their video interviews and hire the best match for their requirements. <br/><br/> Our service saves time, is cost-effective, and ensures high-quality candidate selection.',
    disableInput: true,
    options: [
      {
        text: "I have more questions!",
        value: 'RECRUITER_MORE_QUESTIONS',
        action: 'postback'
      },
      {
        text: 'Thank you',
        value: 'THANK_YOU',
        action: 'postback'
      }],
  },
  "RECRUITER_PRICING":{
    agent: 'bot',
    type: 'button',
    text: `
    <b>Sourcing:</b><br/><br/>
    For candidates who have already been interviewed and selected by HulkHire, our pricing is based on their performance in interviews. <br/><br/>
    The amount charged ranges from 100 INR to 1000 INR, depending on how well they performed during the interview process. <br/><br/>
    Once the amount is paid, the candidate's contact details will be made available to the client.<br/><br/>

    <b>Interviewing:</b><br/>
For interviewing candidates, the pricing model depends on the number of candidates. <br/><br/>
If the client has less than 100 candidates to interview, the price is 75 INR per candidate. <br/>
<br/>If there are between 100 and 500 candidates, the price is 60 INR per candidate. <br/>
<br/>For 500 to 1000 candidates, the price is 30 INR per candidate. <br/>
<br/>If there are more than 1000 candidates, the price drops to 20 INR per candidate.
<br/><br/>
At the end of each month, an invoice will be generated based on the number of candidates interviewed. 
<br/><br/>
This ensures a transparent and fair pricing structure for our clients.`,
    disableInput: true,
    options: [
      {
        text: "I have more questions!",
        value: 'RECRUITER_MORE_QUESTIONS',
        action: 'postback'
      },
      {
        text: 'Thank you',
        value: 'THANK_YOU',
        action: 'postback'
      }],
  },
  "CUSTOMER_SUPPORT":{
    agent: 'bot',
    type: 'button',
    text: 'HulkHire always feels hapy to help customers. <br/><br/>Feel free to get in touch with us on<br/> <br/> Email - <a target="_blank" href="mailto:care@hulkhire.com">care@hulkhire.com</a> <br/> Whatsapp - <a target="_blank" href="https://api.whatsapp.com/send?phone=918886080289&text=Hello%20HulkHire">+91 8886080289</a>',
    disableInput: true,
    options: [
      {
        text: "I have more questions!",
        value: 'RECRUITER_MORE_QUESTIONS',
        action: 'postback'
      },
      {
        text: 'Thank you',
        value: 'THANK_YOU',
        action: 'postback'
      }],
  },
  "CANDIATE_MORE_QUESTIONS":{
    agent: 'bot',
    type: 'button',
    text: 'Please tell us what you would like to discuss?',
    disableInput: true,
    options: [
      {
        text: "How to use HulkHire?",
        value: 'CANDIDATE_WORKS',
        action: 'postback'
      },
      {
        text: "I want to know about pricing",
        value: 'CANDIDATE_PRICING',
        action: 'postback'
      },
      {
        text: 'I want to share Feedback',
        value: 'FEEDBACK',
        action: 'postback'
      },
      {
        text: 'I have a Query',
        value: 'QUERY',
        action: 'postback'
      },
      {
        text: 'I am facing technical issue',
        value: 'TECHNICAL_ASSISTANCE',
        action: 'postback'
      },
      {
        text: 'Not able to find relevant jobs',
        value: 'NO_JOB',
        action: 'postback'
      }
    ],
  },
  "CANDIDATE_WORKS":{
    agent: 'bot',
    type: 'button',
    text: `HulkHire helps you in getting your dream job in 4 simple steps<br/><br/>
    <b> Browse job posts:</b> Start by browsing the job posts on our platform to find the ones that interest you. You can filter the posts by entering keywords related to your job prferences.
    <br/><br/>
    <b>Click on the 'Connect with HR' button:</b> Once you've found a job post that you're interested in, click on the 'Connect with HR' button to initiate a conversation with the HR professional who posted the job.
    <br/><br/>
    <b> Introduce yourself:</b> In your first message to the HR professional, introduce yourself and express your interest in the job. You can also highlight your qualifications and relevant experience to make a strong impression.
    <br/><br/>
    <b>Follow up:</b> If the HR professional responds positively to your message, continue the conversation to learn more about the job and the company. Be sure to follow up promptly and professionally to keep the conversation going.
    <br/><br/>
By following these steps, you can use our platform to connect with HR professionals directly and increase your chances of landing your dream job. Good luck!`,
    disableInput: true,
    options: [
      {
        text: "I have more questions!",
        value: 'CANDIATE_MORE_QUESTIONS',
        action: 'postback'
      },
      {
        text: 'Thank you',
        value: 'THANK_YOU',
        action: 'postback'
      }],
  },
  "CANDIDATE_PRICING": {
    agent: 'bot',
    type: 'button',
    text: ' HulkHire is <b>free</b> for candidates. <br/><br/>We never charge a single penny from candidate. If somebody asks money on behalf of HulkHire, Please report them on <a target="_blank" href="mailto:care@hulkhire.com"> care@hulkhire.com</a>',
    disableInput: true,
    options: [
      {
        text: "I have more questions!",
        value: 'CANDIATE_MORE_QUESTIONS',
        action: 'postback'
      },
      {
        text: 'Thank you',
        value: 'THANK_YOU',
        action: 'postback'
      }],
  },
  "FEEDBACK": {
    agent: 'bot',
    type: 'text',
    text: 'Please share your feedback',
    disableInput: false,
    msgtype:"FEEDBACK"
  },
  "QUERY": {
    agent: 'bot',
    type: 'text',
    text: 'Please share your query',
    disableInput: false,
    msgtype:"QUERY"
  },
  "TECHNICAL_ASSISTANCE": {
    agent: 'bot',
    type: 'text',
    text: 'Please share technical issue details you are facing',
    disableInput: false,
    msgtype:"TECHNICAL_ASSISTANCE"
  },
  "NO_JOB": {
    agent: 'bot',
    type: 'text',
    text: 'Please share your job preferences',
    disableInput: false,
    msgtype:"NO_JOB"
  },
  "THANK_DETAILS_RECRUITER": {
    agent: 'bot',
    type: 'button',
    text: 'Thanks for sharing the details. We will get back to you shortly on your shared email address.',
    disableInput: false,
    options: [
      {
        text: "I have more questions!",
        value: 'RECRUITER_MORE_QUESTIONS',
        action: 'postback'
      },
      {
        text: 'Thank you',
        value: 'THANK_YOU',
        action: 'postback'
      }],
  },
  "THANK_DETAILS": {
    agent: 'bot',
    type: 'button',
    text: 'Thanks for sharing the details. We will get back you shortly on your shared email address.',
    disableInput: false,
    options: [
      {
        text: "I have more questions!",
        value: 'CANDIATE_MORE_QUESTIONS',
        action: 'postback'
      },
      {
        text: 'Thank you',
        value: 'THANK_YOU',
        action: 'postback'
      }],
  },
  "THANK_YOU":{
    agent: 'bot', 
    type: 'text',
    text: 'Good Bye! We wish you a very happy and healthy life!', 
    disableInput: true, 
  }
}

//Connect directly with HR and take the first step towards your dream job! Our platform allows you to connect with HR professionals directly and discuss your qualifications and interests with them. Simply click on the 'Connect with HR' button on any job post that interests you, and start the conversation. This is your chance to stand out from the crowd and make a personal connection with the people who make hiring decisions. So why wait? Connect with HR now and take control of your career!