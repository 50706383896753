<template>
  <v-row justify="center">
    <v-dialog
    :value="show"
      transition="dialog-bottom-transition"
      align="center"
      :max-width="step==1? 1000:500"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
        <v-btn light icon v-bind="attrs"  class="mr-2 mt-2" v-on="on" style="float:right;z-index:500"  @click="oncancel()">
        <v-icon size="28">mdi-close</v-icon>
      </v-btn>
      <v-card v-if="step==0">
        <!-- <v-btn light icon v-bind="attrs"   class="mr-2 mt-2" v-on="on" style="float:right;"  @click="oncancel()">
        <v-icon size="28">mdi-close</v-icon>
      </v-btn> -->
        <!-- <v-icon size="28" style="float:right;" class="mr-2 mt-2">mdi-close</v-icon> -->
        
        <v-card-text class="">
              <div class="text-h6 pt-8" style="color:#000000; text-align:center;" >{{msg}}</div><br/>
              
        </v-card-text>
        <v-card-actions class="pb-8 justify-center">
          <sign-in v-if="dialogaction=='login'"></sign-in>
          <!-- <v-btn @click="redirect()" class="primary" v-else-if="dialogaction=='redirect'">
          
          </v-btn> -->
          <v-btn v-else @click="step=1" color="primary">Complete Profile</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="step==1"
      >
      
        <profile indialog="true"  :complete="()=>step=2" :cancel="oncancel"></profile>
      </v-card>
      <v-card v-else-if="step==2"
      >
        <v-card-text class="justify-center pt-8" style="text-align:center;">
          <v-icon size="60" color="primary">mdi-thumb-up-outline</v-icon>
              <div class="text-h6 pt-8" style="color:#000000; text-align:center;" >Congratulations! You are all set to apply for job.</div><br/>
        </v-card-text>
        <v-card-actions class="pb-8 justify-center">
          <v-btn color="primary" @click="oncomplete">Start Applying !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Profile from '../../pages/Profile/Profile.vue';
import SignIn from '../../components/SignIn/SignIn.vue';

// import SignIn from '../../components/SignIn/SignIn.vue';
export default {
  components: { SignIn, Profile },
  // components: { SignIn },
    props:["show","msg","url","dialogaction","oncomplete","oncancel"],
  data() {
    return {
      step:0
    };
  },

  methods:{
      redirect:function(){
        this.$router.push(this.$props.url)
      }
  },

  mounted(){
    
  }

};
</script>
<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}
</style>