let isProd = location.href.indexOf("localhost") > -1 ? false : true;
isProd=true;
export default {
  light: {
    primary: "#008036",
    secondary: "#FF5C93",
    error: "#FF4081",
    warning: "#FFC260",
    success: "#3CD4A0",
    info: "#9013FE",
    textColor: "#262626",
    iconColor: "#FFFFFF59",
    grayBold: '#262626'
  },
  serverurl: isProd ? 'https://api.hulkhire.com' : "http://localhost:8083"
};

