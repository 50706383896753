<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters v-if="store.profile">
        <v-tabs v-model="active_tab">
          <v-tab>Upcoming </v-tab>
          <v-tab>Completed</v-tab>
        </v-tabs>
        <br />
        <v-data-table
          style="width: 100%"
          :headers="headers"
          :items="selectedinterviews"
          :items-per-page="5"
          class="elevation-1 mt-4"
          width="100%"
          :disable-sort="true"
        >
          <template v-slot:[`item.interviewtime`]="{ item }">
            {{ item.formattedInterviewTime }}
          </template>
          <template v-slot:[`item.formattedstatus`]="{ item }">
            <v-btn outlined small :color="item.formattedstatus=='Shortlisted'? 'primary':'orange'">{{item.formattedstatus}}</v-btn>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="item.actions.length > 0">
              <v-btn text small color="primary" class="pl-0 pr-0 pb-0 d-flex align-self-start" @click="getLink(action, item)" v-for="action in item.actions"
                  v-bind:key="action.text">{{action.text}}</v-btn>
            </div>
            <!-- <v-menu bottom left v-if="item.actions.length > 0"> -->
              
              <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn light icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>

                <v-list-item
                  
                  @click="getLink(item)"
                >
                  <v-list-item-title></v-list-item-title>
                </v-list-item> -->
              <!-- </v-list> -->
            <!-- </v-menu> -->
          </template>
        </v-data-table>
      </v-row>
      <v-row no-gutters v-if="!store.profile">
        <sign-in></sign-in>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import config from "../../config";

let serverurl = config.serverurl;
import { store } from "@/store/index";
import helper from "@/helper";
import { sendLogs } from "@/server/api";
import status from '../../status'
import SignIn from '../../components/SignIn/SignIn.vue';
export default {
  name: "Interviews",
  components: {SignIn},
  data() {
    return {
      active_tab: 0,
      store,
      sendLogs,
      headers: [
        { text: "Company", value: "profile" },
        { text: "Job Title", value: "testname" },
        { text: "Skills", value: "requiredskill" },
        { text: "Interview Time", value: "interviewtime" },
        { text: "Status", value: "formattedstatus" },
        { text: "Actions", value: "actions" },
      ],
      interviews: [],
      selectedinterviews: [],
    };
  },
  watch: {
    active_tab: function (val) {
      if (val == 0)
        this.selectedinterviews = this.interviews.filter(
          (i) => i.type == "PENDING"
        );
      else
        this.selectedinterviews = this.interviews.filter(
          (i) => i.type == "TRACK"
        );
    },
    interviews: function () {
      if (this.active_tab ==0)
        this.selectedinterviews = this.interviews.filter(
          (i) => i.type == "PENDING"
        );
      else
        this.selectedinterviews = this.interviews.filter(
          (i) => i.type == "TRACK"
        );
    },
  },
  methods: {
    getLink: function(action,item){
      console.log(action)
      switch(action.text.toUpperCase()){
        case "RESCHEDULE INTERVIEW": window.open(action.link,"_blank"); break;
        case "JOIN INTERVIEW": window.open(action.link,"_blank"); break;
        case "CANCEL INTERVIEW": action.method(item); break;
      }
    },
    cancelInterview: async function (item) {
      console.log(item.id);
      console.log(item);
       await fetch(serverurl + "/changeCandidateStatus", {
        credentials: "include",
        method:"post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: item.id,
          testid:item.testid,
          status:"Cancelled",
          reason:"Candidate cancelled interview"
        })
      });
      this.getInterviews();
    },
    getInterviews: async function () {
      let interviewresults = await fetch(serverurl + "/candidateinterviews", {
        credentials: "include",
      });
      let interviews = await interviewresults.json();
      interviews.map((s) => {
        s.formattedstatus= status[s.status];
        s.profile = s.profile.split(".")[0].toUpperCase();
        if (s.sourcing_category == "IT")
          s.requiredskill = "Software Development";
        s.interviewtime = new Date(s.interviewtime);
        s.formattedInterviewTime =
          s.interviewtime.toDateString() +
          ", " +
          helper.formatTimeAMPM(s.interviewtime);
        s.type =
          s.status == "Invitation Sent" || s.status == "Pending Invitation"
            ? "PENDING"
            : "TRACK";
        if (s.status.toUpperCase() == "NO SHOW" && s.active)
          s.actions = [
            {
              text: "RESCHEDULE INTERVIEW",
              link:
                "https://jobs.hulkhire.com/reschedule/" +
                s.email +
                "/" +
                s.testid,
            },
          ];
        else if (s.type == "PENDING") {
          s.actions = [
            {
              text: "Join Interview",
              link: s.interviewlink,
            },
            {
              text: "Reschedule Interview",
              link:
                "https://jobs.hulkhire.com/reschedule/" +
                s.email +
                "/" +
                s.testid,
            },
            {
              text: "Cancel Interview",
              method: this.cancelInterview,
            },
          ];
        } else s.actions = [];
      });

      this.interviews = interviews;
    },
  },
  created() {},
  mounted() {
    this.getInterviews();
  },
  beforeDestroy() {
    console.log("unmounted");
  },
};
</script>

<style src="./Interviews.scss" lang="scss"/>
