<template>
    <v-btn @click.stop="onsignIn" color="#0077B5"
    dark
    elevation="2"
    rounded
    class="linkedin-signin" :width="$vuetify.breakpoint.mdAndUp? '250px':'130px'">
    <v-icon class="linkedin-icon">mdi-linkedin</v-icon>
    <span>{{$vuetify.breakpoint.mdAndUp ? "Sign In with LinkedIn" :"Sign In"}}</span>
    </v-btn>

</template>
<script>
import {sendLogs } from "../../server/api";

export default {
  name: "SignIn",
  methods: {
    onsignIn: function(){
    sendLogs({type:'SIGN_IN',log:'' }).then(()=>{
      let state= encodeURIComponent(JSON.stringify({url:location.href}));
      location.href=`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86ej6acokucwcw&redirect_uri=https%3A%2F%2Fapi.hulkhire.com%2Fauthcode&state=${state}&scope=r_liteprofile%20r_emailaddress`;
    });
    
  },
  }
};
</script>

<style src="./SignIn.scss" lang="scss"></style>
