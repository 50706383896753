<template>
  <div>
    <div  v-if="store.profile">
      <v-card class="mb-2 ml-2 mr-2 pa-2 mt-2" v-if="!indialog">
        <h3 class="ma-0 pa-2">
          Profile details
          <v-progress-circular
            v-if="isLoading"
            :size="20"
            :width="3"
            color="primary"
            :rotate="360"
            :indeterminate="true"
          ></v-progress-circular></h3
      ></v-card>
      <div v-for="(k,index) in Object.keys(formfields)"
        v-bind:key="k">
        <v-card
        class="mb-2 ml-2 mr-2 pa-2"
        :class="{ 'dialog-form-card': indialog }"
        v-show="(index==selectedindex) || !indialog"
      >
        <v-form  :ref="k" @submit.prevent="() => onsave(k)">
          <v-row no-gutters>
            <strong class="pl-2 pt-2">{{ formfields[k].name }} </strong>
            <v-spacer></v-spacer>
            <v-progress-circular
              v-if="formfields[k].isLoading"
              :size="20"
              :width="3"
              color="primary"
              :rotate="360"
              :indeterminate="true"
            ></v-progress-circular>
            <v-btn
              color="primary darken-3"
              icon
              small
              v-if="!formfields[k].isEditing && !indialog"
              @click.stop="formfields[k].isEditing = true"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
            <div v-else>
              <div v-if="!indialog">
                <v-btn color="primary darken-3" text @click="oncancel(k)">
                  cancel
                </v-btn>
                <v-btn type="submit" color="primary darken-3" text> Save </v-btn>
              </div>
              <div v-if="indialog" class="bottom-btn">
                <v-btn color=" darken-3" v-if="selectedindex>0" outlined  class="mr-2" @click="selectedindex=selectedindex-1">
                  Back
                </v-btn>
                <v-btn type="submit" color="primary darken-3" > {{ selectedindex!=(Object.keys(formfields).length-1)? "Next":"Save"}} </v-btn>
              </div>
            </div>
          </v-row>

          <v-row class="mt-0 mb-0 ml-0 mr-0">
            <v-col
              cols="12"
              :sm="pd.type == 'autocomplete' && pd.multiple ? 12 : 6"
              :md="pd.type == 'autocomplete' && pd.multiple ? 12 : 4"
              v-for="pd in formfields[k].fields"
              v-bind:key="pd.name"
            >
              <v-file-input
                :required="pd.required"
                :rules="[pd.required ? rules.filerequired : null]"
                v-model="pd.dirtyfield"
                @change="handleFileUpload"
                v-if="pd.type == 'file'"
                :label="pd.name"
                :disabled="!formfields[k].isEditing && !indialog"
              ></v-file-input>
              <v-text-field
                v-if="pd.type == 'text'"
                v-model="pd.dirtyfield"
                :label="pd.name"
                :placeholder="pd.name"
                :rules="[
                  pd.required ? rules.required : null,
                  pd.email ? rules.emailpattern : null,
                ]"
                :max-length="pd.maxlength"
                :required="pd.required"
                :disabled="!formfields[k].isEditing && !indialog"
              ></v-text-field>
              <v-text-field
                v-if="pd.type == 'number'"
                v-model="pd.dirtyfield"
                :label="pd.name"
                :placeholder="pd.name"
                :min="pd.min"
                :max="pd.max"
                hide-details
                :rules="[
                  pd.required ? rules.required : null,
                  pd.min ? rules.min(pd.min) : null,
                  pd.max ? rules.max(pd.max) : null,
                ]"
                type="number"
                :required="pd.required"
                :disabled="!formfields[k].isEditing && !indialog"
              />
              <v-select
                v-if="pd.type == 'dropdown'"
                v-model="pd.dirtyfield"
                :items="pd.items"
                :label="pd.name"
                :placeholder="pd.name"
                :rules="[pd.required ? rules.required : null]"
                :required="pd.required"
                :disabled="!formfields[k].isEditing && !indialog"
              ></v-select>
              <v-autocomplete
                v-if="pd.type == 'autocomplete'"
                v-model="pd.dirtyfield"
                item-text="text"
                item-value="id"
                @change="pd.search = ''"
                :label="pd.name"
                :multiple="pd.multiple"
                :loading="pd.isLoading"
                :required="pd.required"
                @input.native="() => filteredItems(pd)"
                :items="pd.items"
                :menu-props="{ closeOnContentClick: true }"
                :search-input.sync="pd.search"
                :rules="[pd.required ? rules.required : null]"
                hide-no-data
                cache-items
                return-value
                placeholder="Start typing to Search"
                :chips="pd.multiple"
                :deletable-chips="pd.multiple"
                :disabled="!formfields[k].isEditing && !indialog"
              >
                <template v-slot:chip="{ props, item }">
                  <v-chip v-bind="props" :text="item.text"></v-chip>
                </template>
              </v-autocomplete>
              <v-select
                v-if="pd.type == 'multiselect'"
                v-model="pd.dirtyfield"
                :rules="[pd.required ? rules.required : null]"
                :items="pd.options"
                :required="pd.required"
                :placeholder="pd.name"
                :label="pd.name"
                multiple
                :disabled="!formfields[k].isEditing && !indialog"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      </div>
      
    </div>
    <div v-else>
      <v-row  no-gutters class="pl-2 pt-2"> 
          <sign-in></sign-in>
         </v-row>
    </div>
  </div>
</template>
<script>
// import Vue from "vue";
import SignIn from "../../components/SignIn/SignIn.vue"
import { store } from "../../store/index";
import config from "../../config";


let serverurl = config.serverurl;
export default {
  name: "Profile",
  components: {SignIn},
  props:["indialog","complete","cancel"],
  
  data() {
    return {
      selectedindex:0,
      store,
      isLoading: false,
      profile: {},
      resume: null,
      pattern:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      rules: {
        required: (v) => (!!v && v.length > 0) || "This field is required",
        emailpattern: (v) => {
          return this.pattern.test(v) || "Invalid e-mail.";
        },
        filerequired: (v) => {
          return v || "This field is required";
        },
        min: (min) => (v) => v >= min || "Invalid value",
        max: (max) => (v) => v <= max || "Invalid value",
      },
      // clientRules: [
      //   (v) => {
      //     console.log(v);
      //     return (!!v && v.length > 0) || "This field is required";
      //   },
      // ],
      formfields: {
        personal: {
          isEditing: false,
          isLoading: false,
          name: "Personal Details",
          fields: [
            {
              name: "First Name",
              type: "text",
              required: true,
              mappedfield: "firstname",
              maxlength: 40,
              originalfield: null,
              dirtyfield: null,
            },
            {
              name: "Last Name",
              type: "text",
              required: true,
              mappedfield: "lastname",
              maxlength: 40,
              originalfield: null,
              dirtyfield: null,
            },
            {
              name: "Phone number",
              type: "number",
              required: true,
              mappedfield: "phonenumber",
              maxlength: 15,
              originalfield: null,
              dirtyfield: null,
            },
            {
              name: "City",
              type: "autocomplete",
              url: serverurl + "/getCities",
              options: [],
              multiple: false,
              required: true,
              mappedfield: "city",
              originalfield: null,
              dirtyfield: null,
            },
            {
              name: "Email",
              type: "text",
              required: true,
              maxlength: 100,
              mappedfield: "contactemail",
              originalfield: null,
              dirtyfield: null,
              email: true,
            },
            {
              name: "Whatsapp",
              type: "number",
              required: true,
              maxlength: 100,
              mappedfield: "whatsapp",
              originalfield: null,
              dirtyfield: null,
            },
          ],
        },
        resume: {
          isEditing: false,
          isLoading: false,
          name: "Resume",
          fields: [
            {
              name: "Your resume",
              type: "file",
              required: true,
              originalfield: null,
              dirtyfield: null,
            },
          ],
        },

        qualification: {
          isEditing: false,
          isLoading: false,
          name: "Education and Work Experience",
          fields: [
            {
              name: "Qualification",
              type: "dropdown",
              required: true,
              items: [
                "B.Tech",
                "B.E",
                "B.Sc",
                "B.Com",
                "B.C.A",
                "M.C.A",
                "B.A",
                "B.Ed",
                "M.Tech",
                "M.S",
                "Other",
              ],
              mappedfield: "qualification",
              originalfield: null,
              dirtyfield: null,
            },
            {
              name: "Graduation CGPA / percentage",
              type: "number",
              required: true,
              mappedfield: "score",
              maxlength: 40,
              originalfield: null,
              dirtyfield: null,
            },
            {
              name: "Experience (in years)",
              type: "number",
              min: 0,
              max: 30,
              required: true,
              mappedfield: "experience",
              maxlength: 40,
              originalfield: null,
              dirtyfield: null,
            },
            {
              name: "Technical Skills",
              type: "text",
              required: true,
              mappedfield: "technicalskill",
              maxlength: 100,
              originalfield: null,
              dirtyfield: null,
            },
          ],
        },
        preference: {
          isEditing: false,
          isLoading: false,
          name: "Job preference",
          fields: [
            {
              name: "Work Model",
              type: "multiselect",
              maxlength: 40,
              options: ["Hybrid", "Fully Remote", "In Office"],
              required: true,
              mappedfield: "workmodel",
              originalfield: null,
              dirtyfield: null,
            },
            {
              name: "Location Preference",
              type: "autocomplete",
              url: serverurl + "/getAllLocations",
              multiple: true,
              maxlength: 10,
              options: [],
              required: true,
              mappedfield: "location_preferences",
              originalfield: null,
              dirtyfield: null,
            },
          ],
        },
        recruitment: {
          isLoading: false,
          isEditing: false,
          name: "Recruitment",
          fields: [
            {
              name: "Current Company",
              type: "text",
              required: true,
              maxlength: 80,
              mappedfield: "current_company",
              originalfield: null,
              dirtyfield: null,
            },
            {
              name: "Designation",
              type: "text",
              maxlength: 40,
              required: true,
              mappedfield: "designation",
              originalfield: null,
              dirtyfield: null,
            },
            {
              name: "Notice period (in days)",
              type: "number",
              min: 0,
              max: 100,
              required: true,
              mappedfield: "notice_period",
              originalfield: null,
              dirtyfield: null,
            },
            {
              name: "Current Annual Salary (in your local currency)",
              type: "number",
              min: 0,
              max: 100000000,
              required: true,
              mappedfield: "current_ctc",
              originalfield: null,
              dirtyfield: null,
            },
            {
              name: "Expected Annual Salary (in your local currency)",
              type: "number",
              min: 0,
              max: 100000000,
              required: true,
              mappedfield: "expected_ctc",
              originalfield: null,
              dirtyfield: null,
            },
          ],
        },
        social: {
          isLoading: false,
          isEditing: false,
          name: "Social Profiles",
          fields: [
            {
              name: "LinkedIn Profile",
              type: "text",
              required: true,
              maxlength: 200,
              mappedfield: "linkedin",
              originalfield: null,
              dirtyfield: null,
            },
            {
              name: "Github Profile",
              type: "text",
              required: false,
              maxlength: 200,
              mappedfield: "githublink",
              originalfield: null,
              dirtyfield: null,
            },
          ],
        },
      },
    };
  },
  methods: {
    handleFileUpload(file) {
      // const file = event.target.files[0];

      if (file) {
        if (file.size > 900 * 1024) {
          alert("File too large");

          return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContent = e.target.result;
          this.resume = fileContent;
        };
        reader.readAsText(file);
      }
    },
    filteredItems: async (pd) => {
      console.log(pd);
      if (pd.search && pd.search.length > 2) {
        pd.isLoading = true;
        let url = pd.url + "?search=" + pd.search;
        console.log(url);
        let results = await fetch(url);
        let res = await results.json();
        let options = [];
        if (Array.isArray(res)) {
          res.map((c) =>
            options.push({
              text: c.name + ", " + c.state + ", " + c.country,
              id: c.name + "-" + c.stateCode + "-" + c.countryCode,
            })
          );
        }
        if (res.cities)
          res.cities.map((c) =>
            options.push({
              text: c.name + ", " + c.state + ", " + c.country,
              id: c.name + "-" + c.stateCode + "-" + c.countryCode,
            })
          );
        else if (res.country)
          res.country.map((c) =>
            options.push({ text: c.name, id: "C-" + c.code })
          );
        else if (res.states)
          res.states.map((c) =>
            options.push({ text: c.name + ", " + c.country, id: "S-" + c.code })
          );

        pd.isLoading = false;
        pd.items = options;
      }
    },
    oncancel(k) {
      this.formfields[k].isEditing = false;
      console.log(this.formfields[k]);
      this.formfields[k].fields.map((f) => {
        f.dirtyfield = f.originalfield;
      });
      this.$props.cancel()
    },
    onsave(k) {
      if (!this.$refs[k][0].validate()) return;

      this.formfields[k].fields.map((f) => {
        f.originalfield = f.dirtyfield;
      });
      this.saveCandidate(k);
    },
    async saveCandidate(k) {
      if (k == "resume" && !this.resume) {
        this.selectedindex+=1
        return;
      }
      this.formfields[k].isLoading = true;
      let candidate = {
        id: store.profile.id,
        type: "candidate_users",
      };
      if (k == "resume" && this.resume) {
        candidate.resumedata = {
          data: this.resume,
          type: this.formfields[k].fields[0].dirtyfield.type,
          filename: this.formfields[k].fields[0].dirtyfield.name,
        };
      } else
        this.formfields[k].fields.map((t) => {
          candidate[t.mappedfield] = t.dirtyfield;
          if (!t.dirtyfield) candidate[t.mappedfield] = "";
        });

      await fetch(serverurl + "/saveCandidate", {
        credentials: "include",
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(candidate),
      });
      
      if(this.$props.indialog){
        if((this.selectedindex)==(Object.keys(this.formfields).length-1))
          this.$props.complete()
        else
          this.selectedindex+=1;
      }
      this.formfields[k].isLoading = false;
      this.formfields[k].isEditing = false;
    },
    async getCandidateProfile() {
      let candidateresult = await fetch(serverurl + "/candidateprofile", {
        credentials: "include",
      });
      let candidate = candidateresult.json();
      if (!candidate) {
        console.log("login");
        return;
      }
      return candidate;
    },
  },
  mounted: async function () {
    this.isLoading = true;
    let candidate = await this.getCandidateProfile();
    this.isLoading = false;
    Object.keys(this.formfields).map((k) => {
      this.formfields[k].fields.map((f) => {
        if (candidate[f.mappedfield]) {
          if (f.type == "autocomplete" && f.multiple) {
            f.items = candidate[f.mappedfield + "-items"];
            f.originalfield = candidate[f.mappedfield].split(",");
            f.dirtyfield = f.originalfield;
          } else if (f.type == "multiselect") {
            f.originalfield = candidate[f.mappedfield].split(",");
            f.dirtyfield = f.originalfield;
          } else if (f.type == "autocomplete") {
            f.items = candidate[f.mappedfield + "-items"];
            f.originalfield = candidate[f.mappedfield];
            f.dirtyfield = f.originalfield;
          } else if (
            f.type == "text" ||
            f.type == "number" ||
            f.type == "dropdown"
          ) {
            f.originalfield = candidate[f.mappedfield];
            f.dirtyfield = f.originalfield;
          }
        }
        if (f.type == "file" && candidate.resumefile) {
          f.originalfield = new File([""], candidate.resumefile);
          f.dirtyfield = f.originalfield;
        }
      });
    });
  },
};
</script>
<style src="./Profile.scss" lang="scss" />

