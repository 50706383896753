import config from "../config"
let serverurl = config.serverurl;
import {store} from "../store/index";

let headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

export const getUserDetails = () => {
  return fetch(serverurl + "/candidateUserDetails", { credentials: "include" }).then(res => res.json()).then(profile => {
    if (profile.error)
      return null;
    else
      return profile;
  })
}

export const sendFeedback = (data) => {
  return fetch(serverurl + "/feedback", {
    credentials: "include",
    method: 'POST',
    headers,
    body: JSON.stringify(data)
  });
}

export const sendLogs = (data) => {
  if (!data.email) {
    if (store.profile && store.profile.email)
      data.email = store.profile.email;
    else
      data.email="ANONYMOUS"
  }
  if (!localStorage.getItem("tempid")) {
    let tempid = Date.now().toString(36) + Math.random().toString(36).substring(2);
    localStorage.setItem("tempid", tempid);
  }
  data.uniqueid= localStorage.getItem("tempid");

  return fetch(serverurl + "/candidatelogs", {
    credentials: "include",
    method: 'POST',
    headers,
    body: JSON.stringify(data)
  });
}

