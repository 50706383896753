<template>
  <v-app class="pa-6">
    <Header />
    <Sidebar/>
    <v-main class="content">
      <router-view />
      <bot></bot>
      <Footer style="display: none" />
    </v-main>
    <!-- <v-tour name="myTour" :steps="steps">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
          </v-step>
        </transition>
      </template>
    </v-tour> -->
  </v-app>
</template>

<script>
import Header from "@/components/Header/Header";
import Sidebar from "@/components/Sidebar/Sidebar";
import Footer from "@/components/Footer/Footer";
import Bot from "@/bot.vue"
import Vue from "vue";
import VueTour from "vue-tour";
Vue.use(VueTour);
import "./Layout.scss";
import "vue-tour/dist/vue-tour.css";
import { store } from "../../store/index";
import steps from "../../tour-steps";


console.log(store);
export default {
  name: "Layout",
  components: { 
    Footer, Header, Bot,
    
    Sidebar 
    },
  data() {
    return {
      store,
      steps
    };
  },
  mounted: function () {
    // if(location.href.toLowerCase().indexOf("job")==-1)
    //   setTimeout(()=>this.$tours["myTour"].start(), 1000)
  },
};
</script>

<style src="./Layout.scss" lang="scss" />