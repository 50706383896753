


function getCookie(cookieName) {
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
      const [name, value] = cookies[i].split('=');
      if (name === cookieName) {
        return decodeURIComponent(value);
      }
    }
    return null; // Cookie not found
  }

  function formatTimeAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}


function getTimezoneOffset( tz) {
  let d= new Date()
const a = d.toLocaleString("ja", {timeZone: tz}).split(/[/\s:]/);
a[1]--;
const t1 = Date.UTC.apply(null, a);
const t2 = new Date(d).setMilliseconds(0);
let offsetInMinutes= (t2 - t1) / 60 / 1000;
  console.log(offsetInMinutes)
  // Convert the offset to UTC format
const sign = offsetInMinutes < 0 ? '+' : '-';
  offsetInMinutes= Math.abs(offsetInMinutes)
const hours = Math.abs(Math.floor(offsetInMinutes / 60));
  console.log(hours)
const minutes = Math.abs(offsetInMinutes % 60);
  console.log(minutes)
return `UTC`+`${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

}
export default { getCookie, formatTimeAMPM, getTimezoneOffset }