
import Vue from 'vue';
// console.log(reactive);

export const store = Vue.observable({
  drawer: window.innerWidth<500? false: true,
  profile:null,
  candidateAccess:null,
  cartCandidates:[],
  toggleDrawer: function(){

    this.drawer = !this.drawer;
  }
})

// Vue.use(Vuex);

// export default new Vuex.Store({

//   namespace: true,
//   state: {
//     drawer: true,
//     profile : null
//   },
//   mutations: {
//     toggleDrawer(state) {
//       state.drawer = !state.drawer;
//     }
//   },
//   actions: {
//     TOGGLE_DRAWER({ commit }) {
//       commit('toggleDrawer');
//     }
//   },
//   getters: {
//     DRAWER_STATE(state) {
//       return state.drawer;
//     }
//   }
// });
