<template>
  <v-container fluid>
    <div class="dashboard-page">
      <div class="d-flex">
        <v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters>
          <v-select
            width="200px"
            :items="skillOptions"
            v-model="skill"
            item-text="name"
            item-value="id"
            @change="
              start = 0;
              jobs = [];
              getJobs();
            "
            class="pa-0 experience mr-2"
            outlined
            style="border-radius: 20px; flex-grow: 0"
          >
            <template v-slot:selection="{ item }">
              <span class="pl-2" style="color: black">
                Skill - {{ item.name }}</span
              >
            </template>
          </v-select>
          <v-select
            width="200px"
            :items="locationOptions"
            v-model="location"
            item-text="name"
            item-value="id"
            @change="
              start = 0;
              jobs = [];
              getJobs();
            "
            class="pa-0 experience mr-2"
            outlined
            style="border-radius: 20px; flex-grow: 0"
          >
            <template v-slot:selection="{ item }">
              <span class="pl-2" style="color: black">
                Location - {{ item.name }}</span
              >
            </template>
          </v-select>
          <v-select
            width="200px"
            :items="numberOptions"
            v-model="experience"
            item-text="text"
            item-value="id"
            @change="
              start = 0;
              jobs = [];
              getJobs();
            "
            class="pa-0 experience"
            outlined
            style="border-radius: 20px; flex-grow: 0"
          >
            <template v-slot:selection="{ item }">
              <span class="pl-2" style="color: black">
                Experience - {{ item.text }}</span
              >
            </template>
          </v-select>
        </v-row>
      </div>
      <v-expansion-panels v-model="panel" v-if="!$vuetify.breakpoint.mdAndUp">
        <v-expansion-panel>
          <v-expansion-panel-header
            hide-actions
            expand-icon="mdi-filter-variant"
            class="pa-4 align-center"
          >
            <div v-if="panel !== 0">
              {{ skill!=null? skillOptions.find((o) => o.id == skill).name :  "Any"  }} •
              {{
                experience === 0
                  ? "Fresher"
                  : experience
                  ? experience + " Yrs"
                  : "Any"
              }}
              •
              {{ location !=null? locationOptions.find((o) => o.id == location).name  : "Any" }}
            </div>
            <div v-if="panel === 0">
              <v-icon> mdi-arrow-left </v-icon>
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="pa-0">
            <v-select
              width="200px"
              :items="skillOptions"
              v-model="skill"
              item-text="name"
              item-value="id"
              @change="
                start = 0;
                jobs = [];
                getJobs();
              "
              class="pa-0 experience mr-2"
              outlined
              style="border-radius: 20px; flex-grow: 0"
            >
              <template v-slot:selection="{ item }">
                <span class="pl-2" style="color: black">
                  Skill - {{ item.name }}</span
                >
              </template>
            </v-select>
            <v-select
              width="200px"
              :items="locationOptions"
              v-model="location"
              item-text="name"
              item-value="id"
              @change="
                start = 0;
                jobs = [];
                getJobs();
              "
              class="pa-0 experience mr-2"
              outlined
              style="border-radius: 20px; flex-grow: 0"
            >
              <template v-slot:selection="{ item }">
                <span class="pl-2" style="color: black">
                  Location - {{ item.name }}</span
                >
              </template>
            </v-select>
            <v-select
              width="200px"
              :items="numberOptions"
              v-model="experience"
              item-text="text"
              item-value="id"
              @change="
                start = 0;
                jobs = [];
                getJobs();
              "
              class="pa-0 experience"
              outlined
              style="border-radius: 20px; flex-grow: 0"
            >
              <template v-slot:selection="{ item }">
                <span class="pl-2" style="color: black">
                  Experience - {{ item.text }}</span
                >
              </template>
            </v-select>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div
        v-if="!loadingJobs && jobs.length == 0"
        style="
          font-size: 16px;
          padding: 10px;
          border: 2px solid #008060;
          background: #fff;
        "
      >
        <b>Sorry! No matching jobs found</b><br />
        <span style="font-size: 14px; color: gray; display: block" class="mt-2">
          Try changing filtration criteria <br />or <br />
          Try again in few days</span
        >
      </div>
      <v-progress-circular
        class="d-flex ml-auto mr-auto"
        v-if="loadingJobs && jobs.length == 0"
        :size="50"
        :width="3"
        color="primary"
        :rotate="360"
        :indeterminate="true"
      ></v-progress-circular>
      <!-- v-show="$vuetify.breakpoint.mdAndUp || panel!=0" -->
      <div class="ml-auto mr-auto" style="max-width: 800px">
        <div class="d-flex justify-space-between mt-2 mb-2">
          <span class="text-body-1 subheader mt-4">Jobs({{ totalJobs }})</span>
          <v-btn
            v-if="interviewrequest"
            color="primary"
            target="_blank"
            href="https://jobs.hulkhire.com/schedule/hulkhire/software%20engineer/183/1"
          >
            Schedule My Interview
          </v-btn>
        </div>

        <v-row
          id="infinite-list"
          style="
            display: grid;
            margin: auto;
            grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
            max-height: calc(100vh - 215px);
            height: calc(100vh - 215px);
            overflow: auto;
          "
        >
          <v-card
            class="d-flex flex-column w-100 pl-3 pr-3 pt-4 pb-4 ml-auto mr-auto mb-2 rounded-lg"
            :class="{ 'has-job-child': job.job_title }"
            width="800px"
            max-width="100%"
            v-for="(job, index) in jobs"
            v-bind:key="job.id"
          >
            <v-row class="d-flex">
              <v-col cols="2" md="1"
                ><img
                  style="border-radius: 50%"
                  :src="
                    job.profile_img.indexOf('base64') > -1
                      ? job.profile_img
                      : '/linked.svg'
                  "
                  width="100%"
              /></v-col>
              <v-col cols="10" md="8" class="d-flex flex-column">
                <div class="text-subtitle-2">{{ job.profile_name }}</div>
                <div class="text-caption">
                  {{ job.profile_description.substring(0, 35) + "..." }}
                </div>
                <div class="text-caption">{{ job.posted_on }}<br /></div>
              </v-col>
              <v-col cols="4" md="3" class="pa-0 pl-2 mb-2">
            
                <v-btn
                  class="connect primary"
                  style="float: right"
                  rounded
                  small
                  v-if="!job.applied"
                  @click="openPost(job)"
                >
                  <!-- <v-icon left> mdi-hand-pointing-right </v-icon> -->
                  Apply</v-btn>
                  <v-chip class="mt-2 mr-2 connect" color="green" style="float: right" outlined pill v-else >
                    <v-icon class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
                    Applied</v-chip>
                    <!-- v-if="job.applying" -->
                      <v-progress-circular
              v-if="job.applying"
              :size="20"
              :width="3"
              color="primary"
              :rotate="360"
              :indeterminate="true"
              style="float: right"
              class="mt-4 mr-2 connect"
            ></v-progress-circular>
                
                
              </v-col>
            </v-row>
            <div style="position: relative">
              <v-row width="100%" class="post-content mt-0">
                <v-expand-transition>
                  <div
                    style="height: 190px; overflow: hidden"
                    :class="{ 'height-auto': job.show }"
                  >
                    <v-card-text class="row">
                      <v-col cols="12" md="6" sm="12" class="pt-1 pb-1">
                        <b>Experience:</b> {{ getExperienceTxt(job) }}
                      </v-col>
                      <v-col cols="12" md="6" sm="12" class="pt-1 pb-1">
                        <b>Skills:</b> {{ getSkills(job) }}
                      </v-col>
                      <v-col cols="12" md="6" sm="12" class="pt-1 pb-1">
                        <b>Location:</b> {{ getLocations(job) }}
                      </v-col>
                      <v-col cols="12" md="6" sm="12" class="pt-1 pb-1">
                        <b>HR Email:</b>
                        <span v-if="job.emails.indexOf('@')>-1">
                          <a
                            class="pl-1"
                            v-bind:key="s"
                            :href="'mailto:' + s.toLowerCase()"
                            v-for="s in job.emails.split(',')"
                            >{{ s.toLowerCase() }}</a
                          >
                        </span>
                        <span class="pl-1" v-else>{{job.emails !='null'? job.emails : 'Not Shared'}}</span>
                      </v-col>
                    </v-card-text>

                    <b class="pl-4" style="font-size: 14px">Job Description:</b>
                    <v-card-text
                      v-html="job.post_text"
                      :ref="'job' + job.id"
                    ></v-card-text>
                  </div>
                </v-expand-transition>
              </v-row>

              <v-btn
                v-if="showSeeMore(index)"
                color=" lighten-2 "
                width="100"
                x-small
                class="see-more"
                style="position: absolute; right: 0"
                @click.stop="
                  job.show = !job.show;
                  sendLogs({ type: 'SEE_MORE', log: job.id });
                "
                text
                >{{ job.show ? "SEE LESS" : "...SEE MORE" }}
              </v-btn>
            </div>
            <v-row
              v-if="job.job_title"
              class="ml-1 mr-1 pa-2 rounded-lg job-child"
              style="border: 1px solid #cccccc"
            >
              <v-col cols="9" class="pa-0">
                <div class="text-subtitle-2" v-html="job.job_title"></div>
                <div class="text-caption" v-html="job.job_subtitle"></div>
                <div class="text-caption" v-html="job.job_description"></div>
              </v-col>
              <v-col cols="3" class="pa-0">
                <v-btn
                  outlined
                  style="float: right"
                  rounded
                  color="#0C66C4"
                  small
                  @click="openJob(job)"
                  >View Job</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <v-progress-circular
          class="d-flex ml-auto mr-auto"
          v-if="loadingJobs && jobs.length > 0"
          :size="50"
          :width="3"
          color="primary"
          :rotate="360"
          :indeterminate="true"
        ></v-progress-circular>
      </div>
    </div>
    <redirect-popup
    v-if="showDialog"
      :show="showDialog"
      :msg="msg"
      :dialogaction="dialogaction"
      url="/profile"
      :oncomplete="()=>{showDialog=false;profilevalid=true;}"
      :oncancel="()=>{showDialog=false;}"
    ></redirect-popup>
    <!-- <interview-schedule v-if="interviewrequest"></interview-schedule> -->
    <!-- <choose-technology
      :show="showChooseTechnology"
      :onWordsChange="
        (words) => {
          showChooseTechnology = false;
          start = 0;
          jobs = [];
          searchKeywords = words;
          getJobs();
        }
      "
    ></choose-technology> -->
  </v-container>
</template>

<script>
import config from "../../config";
// import ChooseTechnology from "./ChooseTechnology.vue";
// import InterviewSchedule from "./InterviewSchedule.vue";
// import SearchKeywordComboBox from "./SearchKeywordComboBox.vue";
let serverurl = config.serverurl;
import { store } from "@/store/index";
import { sendLogs } from "@/server/api";
import RedirectPopup from "./RedirectPopup.vue";

// let last_searched = [];
// let last_location = [];
// if (localStorage.getItem("HH_LAST_SEARCH_WORDS")) {
//   last_searched = JSON.parse(localStorage.getItem("HH_LAST_SEARCH_WORDS"));
//   sendLogs({
//     type: "LOCALSTORAGE_LOADED",
//     log: localStorage.getItem("HH_LAST_SEARCH_WORDS"),
//   });
// }
// if (localStorage.getItem("HH_LAST_SEARCH_LOCATION")) {
//   // last_location = JSON.parse(localStorage.getItem("HH_LAST_SEARCH_LOCATION"));
//   sendLogs({
//     type: "LOCALSTORAGE_LOADED",
//     log: localStorage.getItem("HH_LAST_SEARCH_LOCATION "),
//   });
// }

export default {
  name: "Jobs",
  components: {
    // ChooseTechnology,
    // SearchKeywordComboBox,
    // InterviewSchedule,
    RedirectPopup,
  },
  data() {
    return {
      msg: false,
      profilevalid: true,
      showDialog: false,
      appliedjobids:[],
      interviewrequest: true,
      panel: 1,
      store,
      dialogaction:null,
      searchKeywords: "Any",
      // locationsearch: last_location || [],
      allLocations: [],
      // locationsearchtext: null,
      isLoadingLocations: false,
      jobs: [],
      totalJobs: null,
      start: 0,
      postHeights: [],
      loadingJobs: false,
      showChooseTechnology: false,
      sendLogs,
      experience: null,
      skill: null,
      location: null,
      locationOptions: [],
      skillOptions: [],
      numberOptions: [
        {
          id: null,
          text: "Any",
        },
        {
          id: 0,
          text: "Fresher",
        },
        {
          id: 1,
          text: "1 year",
        },
        {
          id: 2,
          text: "2 years",
        },
        {
          id: 3,
          text: "3 Years",
        },
        {
          id: 4,
          text: "4 Years",
        },
        {
          id: 5,
          text: "5 years",
        },
        {
          id: 6,
          text: "6 Years",
        },
        {
          id: 7,
          text: "7 Years",
        },
        {
          id: 8,
          text: "8 Years",
        },
        {
          id: 9,
          text: "9 Years",
        },
        {
          id: 10,
          text: "10+ Years",
        },
      ],
    };
  },
  watch: {
    locationsearchtext(val) {
      val && this.locationsearch.indexOf(val) == -1 && this.getLocations(val);
    },
    locationsearch() {
      localStorage.setItem(
        "HH_LAST_SEARCH_LOCATION",
        JSON.stringify(this.locationsearch)
      );
      sendLogs({
        type: "SEARCH_LOCATIONS",
        log: JSON.stringify(this.locationsearch),
      });
      this.start = 0;
      this.jobs = [];
      this.getJobs();
    },
  },
  methods: {
    getExperienceTxt(job) {
      if (job.min_exp == 0 && job.max_exp == 0) return "Any";
      else if (job.min_exp == 0 && job.max_exp > 0)
        return "Max " + job.max_exp + " yrs.";
      else if (job.min_exp > 0 && job.max_exp == 0)
        return "Min " + job.min_exp + " yrs.";
      else if (job.min_exp > 0 && job.max_exp > 0)
        return job.min_exp + " to " + job.max_exp + " Yrs" + " required";
    },
    getSkills(job) {
      console.log(job.skills);
      if (!job.skills) return "NA";
      let s = [...new Set(job.skills.split(","))];
      return s
        .map((i) => this.skillOptions.find((o) => o.id == i).name)
        .join(", ");
    },
    getLocations(job) {
      if (!job.locations) return "NA";
      let s = [...new Set(job.locations.split(","))];
      return s
        .map((i) => this.locationOptions.find((o) => o.id == i).name)
        .join(", ");
    },
    async populateSkills() {
      let skillresults = await fetch(serverurl + "/job_skills", {
        credentials: "include",
      });
      let skills = await skillresults.json();
      this.skillOptions = [{ id: null, name: "Any" }].concat(skills);
    },
    async populateLocations() {
      let locationresults = await fetch(serverurl + "/job_locations", {
        credentials: "include",
      });
      let locations = await locationresults.json();
      this.locationOptions = [{ id:null, name: "Any" }].concat(locations);
    },
    // async getLocations(val) {
    //   this.isLoadingLocations = true;
    //   let locationresults = await fetch(
    //     "https://api.hulkhire.com/getJobLocations?search=" + val,{credentials:'include'}
    //   );
    //   let locations = await locationresults.json();
    //   this.allLocations = [];
    //   if (Array.isArray(locations)) {
    //     locations.map((c) =>
    //       this.allLocations.push({
    //         text: c.name + ", " + c.state + ", " + c.country,
    //         id: c.name + "-" + c.stateCode + "-" + c.countryCode,
    //       })
    //     );
    //   }
    //   this.isLoadingLocations = false;
    // },
    async openPost(job) {
      sendLogs({ type: "POST_APPLIED", log: job.id });
      if (!store.profile) {
        this.msg = "Let's login first to apply for job";
        this.dialogaction="login"
        this.showDialog = true;
      } else {
        if (!this.profilevalid) {
          this.msg = "To apply for job, please complete your profile.  These details will be shared to recruiters for further processing.";
          this.dialogaction="redirect"
          this.showDialog = true;

          return;
        }
        job.applying = true;
        await fetch(serverurl + "/applyjob", {
          credentials: "include",
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            job_id: job.id,
          }),
        });

        await (new Promise(resolve => setTimeout(resolve, 2000)))
        job.applying = false;
        job.applied=true;


        this.appliedjobids.push(job.id)

      }
    },
    openJob(job) {
      sendLogs({ type: "JOB_OPENED", log: job.job_link });
      window.open(job.job_link, "_blank");
    },
    getElementHeights() {
      this.$nextTick(() => {
        this.postHeights = this.jobs.map((job) => {
          // const textElement =document.getElementsByClassName("job" + job.id)[0]
          const textElement = this.$refs["job" + job.id][0];
          return textElement ? textElement.offsetHeight : 0;
        });
      });
    },
    showSeeMore(index) {
      return this.postHeights[index] > 120;
    },
    formatDate(date) {
      date = new Date(date);
      let datestring = "";
      const today = new Date();

      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if (today.toDateString() === date.toDateString()) datestring = "TODAY";
      else if (yesterday.toDateString() === date.toDateString())
        datestring = "YESTERDAY";
      else datestring = date.toDateString();
      return datestring;
    },
    getJobs() {
      this.loadingJobs = true;
      // let words = this.skill!="Any"?[this.skill]: [];
      sendLogs({
        type: "JOBS_REQUESTED",
        log: JSON.stringify({
          start: this.start,
          words: JSON.stringify(
            this.skill + "-" + this.location + "-" + this.experience
          ),
        }),
      });

      fetch(serverurl + "/getJobs", {
        credentials: "include",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          searchvalues: this.skill != null ? [this.skill] : null,
          searchlocations: this.location != null ? [this.location] : null,
          experience: this.experience,
          pagination: { start: this.start },
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          res.jobs.map((j) => {
            j.applying=false;
            console.log(this.appliedjobids)
            console.log(this.appliedjobids.find)
            console.log(j.id)

            if(this.appliedjobids.indexOf(j.id)>-1)
              j.applied=true;
            j.show = false;
            // words.map((w) => (w.selected = false));
            j.posted_on = this.formatDate(j.posted_on);
            // let div=document.createElement("div");
            // div.innerHTML=j.post_text;
            // div.querySelectorAll("a").forEach(a=> a.setAttribute("target","_blank"));
            // let findParent= div.querySelector("a").parentElement;
            // const childNodes = findParent.children;
            // for (let i = childNodes.length - 1; i >= 0; i--) {
            //   if(childNodes[i].tagName=="A" && childNodes[i].innerText && childNodes[i].innerText.indexOf("#")>-1)
            //       childNodes[i].remove()
            //   else if(childNodes[i].innerText && childNodes[i].innerText.indexOf("#")==-1)
            //       break;
            // }
            // j.post_text= div.innerHTML;

            // words.map((s) => {
            //   //eslint-disable-line
            //   let re = new RegExp(s.text, "ig");
            //   j.post_text = j.post_text.replace(re, `<mark>${s.text}</mark>`);
            //   j.job_title &&
            //     (j.job_title = j.job_title.replace(
            //       re,
            //       `<mark>${s.text}</mark>`
            //     ));
            //   j.job_subtitle &&
            //     (j.job_subtitle = j.job_subtitle.replace(
            //       re,
            //       `<mark>${s.text}</mark>`
            //     ));
            //   j.job_description &&
            //     (j.job_description = j.job_description.replace(
            //       re,
            //       `<mark>${s.text}</mark>`
            //     ));
            //   if (
            //     j.post_text.indexOf(s.text) > -1 ||
            //     (j.job_title &&
            //       (j.job_title.indexOf(s.text) > -1 ||
            //         j.job_subtitle.indexOf(s.text) > -1 ||
            //         j.job_description.indexOf(s.text) > -1))
            //   )
            //     s.selected = true;
            // });

            // if (words.length == 0) j.selected = true;
            // else {
            //   if (!words.find((w) => !w.selected))
            //     // if(!words.filter(w=>w.text.toLowerCase().indexOf('java')==-1).find(w=>!w.selected) && (
            //     // words.find(w=> ((w.text.toLowerCase().indexOf("java")>-1) && w.selected) ) ||
            //     // !words.find(w=>(w.text.toLowerCase().indexOf("java")>-1))
            //     // ))
            //     j.selected = true;
            //   else j.selected = false;
            // }
          });

          this.jobs = this.jobs.concat(res.jobs);
          this.totalJobs = res.count[0].count;
          this.getElementHeights();
          this.start = this.start + 20;
          this.loadingJobs = false;
        });
    },
    async checkProfile() {
      console.log( "profile status")
      console.log( store.profile)
      if (document.cookie.indexOf("jobs_token")>-1) {
        try{        let profileresults = await fetch(serverurl + "/candidateprofile", {
          credentials: "include",
        });
        let isvalid = true;
        let profile = await profileresults.json();
        let validprops = [
          "firstname",
          "lastname",
          "email",
          "contactemail",
          "phonenumber",
          "city",
          "whatsapp",
          "qualification",
          "experience",
          "technicalskill",
          "linkedin",
          "notice_period",
          "current_ctc",
          "expected_ctc",
          "workmodel",
          "location_preferences",
          "current_company",
          "designation",
          "resumefile",
        ];
        validprops.map((s) => {
          if (!profile[s]) isvalid = false;
        });
        this.profilevalid = isvalid;
        }
        catch{
          this.profilevalid = false;
        }
      }
      else{
        this.profilevalid = false;
      }
    },
    async getAppliedJobs(){
      try{
      let jobresults=await fetch(serverurl+"/getmyjobids", {credentials:'include'});
      this.appliedjobids= await jobresults.json()
      }
      catch{
          this.appliedjobids=[]
      }
    }
  },
  async created() {
    let interviewresults = await fetch(serverurl + "/candidateinterviews", {
      credentials: "include",
    });
    let interviews = await interviewresults.json();
    interviews.find((s) => s.sourcing_category)
      ? (this.interviewrequest = false)
      : null;
  },
  async mounted() {
    const listElm = document.querySelector("#infinite-list");
    listElm.addEventListener("scroll", () => {
      if (
        this.start + 20 < this.totalJobs &&
        this.start > 0 &&
        !this.loadingJobs
      ) {
        if (
          listElm.scrollTop + listElm.clientHeight >=
          listElm.scrollHeight - 100
        ) {
          this.getJobs();
          sendLogs({
            type: "SCROLL_BOTTOM",
            log: JSON.stringify({
              start: this.start,
              words: JSON.stringify(this.searchKeywords),
            }),
          });
        }
      }
    });
    this.populateSkills();
    this.populateLocations();
    this.checkProfile();
    await this.getAppliedJobs();
    this.getJobs();
    
    // if (!last_searched || last_searched.length == 0) {
    //   this.showChooseTechnology = true;
    //   sendLogs({ type: "CHOOSE_TECHNOLOGY_SHOW", log: "true" });
    // }
  },
  beforeDestroy() {
    console.log("unmounted");
  },
};
</script>

<style src="./Jobs.scss" lang="scss"/>
