<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters v-if="store.profile">
        <div>No upcomng Tech talks</div>
      </v-row>
      <v-row no-gutters v-if="!store.profile">
        <sign-in></sign-in>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import { store } from "@/store/index";
import SignIn from '../../components/SignIn/SignIn.vue';
export default {
  name: "TechTalks",
  components: {SignIn},
  data() {
    return {
      store
    };
  }
};
</script>

<style src="./TechTalks.scss" lang="scss"/>
