<template>
  <v-container class="faq">
    <h4>FAQs</h4>
    <v-expansion-panels>
      <v-expansion-panel v-for="(item, index) in faqs" :key="index">
        <v-expansion-panel-header class="font-weight-bold align-start"> {{ (index+1 )+".   "}}<span class="ml-1" style="line-height:18px;">{{item.q }}</span></v-expansion-panel-header>
        <v-expansion-panel-content class="pl-8 pr-8 mb-4"  v-html=" item.a">
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          q: "Why I need to verify with LinkedIn",
          a: "We want only legitimate profiles to give interview on our platform. LinkedIn help us in verifying your identity.",
        },
        {
          q: "Could you provide more details about the job?",
          a: "We are currently conducting interviews for multiple clients who are seeking candidates with skills in any of them - Coding, React, Angular, Java, Python, and NodeJS. During the interview process, you will have the option to choose your preferred technology.",
        },
        
        {
          q: "What is the job location? Is it remote or in-office?",
          a: "Our clients have multiple job openings, some of which are fully remote. During the interview process, we will gather your location preference and work model preferences. Your profile will only be shared with clients who match your location preference.",
        },
        {
          q: "How much salary can I expect if I pass the interview?",
          a: "During the interview process, we will ask about your salary expectations. If your expectations align with your interview results, you will have a better chance of receiving an offer.",
        },
        {
          q: "Can I take a practice test before the interview?",
          a: "<div>We strongly recommend that every candidate go through a practice interview before their actual interview. This will help you understand the interview format and identify any technical issues. Here is the link for the <a target=\"_blank\" href=\"https://interview.hulkhire.com/?type=practice\"> practice interview</a></div>",
        },
        {
          q: "What should I prepare for the interview?",
          a: "We suggest that you prepare for one of the following skills: React, Angular, Java, Python, and NodeJS.",
        },
        {
          q: "Can I use my mobile phone for the interview?",
          a: "We do not support mobile devices as you will be required to do coding during the interview. Please arrange a laptop or desktop with a working camera and microphone.",
        },
        {
          q: "What do I need to prepare for the interview?",
          a: "To participate in the interview, you need to be in a noise-free environment with proper lighting. You also need a laptop or desktop with a stable, high-speed internet connection.",
        },
        {
          q: "Is the interview virtual?",
          a: "Yes, it is a virtual interview. You will receive questions on your screen and will be required to speak your answers. Your video will be recorded during the interview and evaluated by our clients.",
        },
        {
          q: "Can I schedule an interview on the weekend?",
          a: "Yes, since it is a virtual interview, you can schedule your interview for any available slot based on your availability.",
        },
        {
          q: "Can I reschedule my interview?",
          a: "<div>Yes, we understand that unexpected circumstances may arise. To reschedule your interview, please sign in with your LinkedIn account  <a target=\"_blank\" href=\"https://jobs.hulkhire.com\"> here</a>, go to interviews, and click on reschedule.</div>",
        },
        {
          q: "How many questions will be asked during the interview?",
          a: "The coding round will be 30 minutes long and consist of exactly two questions. The technical round will be 10 minutes long, with one minute given for each question. If you do not know the answer, you will be given the option to skip the question.",
        },
        {
          q: "What is the criteria for selection?",
          a: "We recommend that candidates explain their technical understanding in detail. The number of questions attempted does not matter; instead, focus on clearly explaining the concepts.",
        },
        {
          q: "I did not receive an interview link via email. What should I do?",
          a: "<div>Please sign in with your LinkedIn account  <a target=\"_blank\" href=\"https://jobs.hulkhire.com\"> here</a>, Click on interviews, and then click on join interview.</div>",
        },
        {
          q: "I have already completed the interview. How can I track my application?",
          a: "<div>You can check the status of your application by signing in with your LinkedIn account  <a target=\"_blank\" href=\"https://jobs.hulkhire.com\"> here</a>, Click on interviews, and select completed tab.</div>",
        },
        {
          q: "I want to give interview again. How to do it?",
          a: "Yes, You can give interview again after 90 days.",
        },
        {
          q: "I am experiencing technical issues during my interview. What should I do?",
          a: "If you are facing technical issues during the interview, please reach out to us on WhatsApp at +9188860 80289 with screenshot and details",
        },
      ],
    };
  },
  methods:{
    toggle(index){
      this.faqs[index].open=!this.faqs[index].open
    }
  }
};
</script>

